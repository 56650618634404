import { isLogged, isMaster, isCustomer } from '@/helpers/authHelper'

import MainPage from '@/views/MainPage/MainPage'
import ErrorPage from '@/views/Error/ErrorPage'

// MAIN AND ABOUT
const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      layout: 'main'
    },
    component: MainPage
  },

  {
    path: '/about',
    name: 'about',
    meta: {
      layout: 'main'
    },
    component: () => import('../views/AboutUsPage/AboutUsPage.vue')
  },

  // AUTH

  {
    path: '/login',
    name: 'login',
    meta: {
      layout: 'empty'
    },
    component: () => import('../views/Auth/Login.vue')
  },
  {
    path: '/reset_password',
    name: 'reset_password',
    meta: {
      layout: 'empty'
    },
    component: () => import('../views/Auth/ResetPassword.vue')
  },
  {
    path: '/reset_password_page',
    name: 'reset_password_page',
    meta: {
      layout: 'empty'
    },
    component: () => import('../views/Auth/ResetPasswordPage.vue')
  },
  {
    path: '/registration_customer',
    name: 'RegistrationCustomer',
    meta: {
      layout: 'empty'
    },
    component: () => import('../views/Auth/RegistrationCustomer.vue')
  },
  {
    path: '/registration_master',
    name: 'RegistrationMaster',
    meta: {
      layout: 'empty'
    },
    component: () => import('../views/Auth/RegistrationMaster.vue')
  },

  // COMMON PATH

  {
    path: '/main',
    name: 'Main',
    props: true,
    component: () => {
      switch (isCustomer()) {
        case true:
          return import('../views/Customer/Main.vue')
        case false:
          return import('../views/Master/Main.vue')

        default:
          return MainPage
      }
    },
    beforeEnter: (to, from, next) => {
      if (!isLogged()) next('/login')
      else next()
    }
  },

  {
    path: '/set_profile',
    name: 'SetProfile',
    component: () => {
      switch (isCustomer()) {
        case true:
          return import('../views/Customer/SetProfile.vue')
        case false:
          return import('../views/Master/SetProfile.vue')

        default:
          return MainPage
      }
    },
    beforeEnter: (to, from, next) => {
      if (!isLogged()) next('/')
      next()
    }
  },

  {
    path: '/tenders',
    name: 'Tenders',
    component: () => {
      if (isLogged()) {
        if (isCustomer()) {
          return import('../views/Customer/Tenders.vue')
        } else {
          return import('../views/Master/Main')
        }
      } else {
        return import("../views/Auth/RegistrationCustomer.vue");
      }
    },
    beforeEnter: (to, from, next) => {
      if (!isLogged()) next('/login')
      next()
    }
  },

  {
    path: '/tender/:uuid',
    name: 'Tender',
    props: true,
    component: () => {
      switch (isCustomer()) {
        case true:
          return import('../views/Customer/Tender.vue')
        case false:
          return import('../views/Master/Tender.vue')

        default:
          return MainPage
      }
    },
    beforeEnter: (to, from, next) => {
      if (!isLogged()) next('/')
      next()
    }
  },

  {
    path: '/my_tenders',
    name: 'MyTenders',
    component: () => {
      switch (isCustomer()) {
        case true:
          return import('../views/Customer/MyTenders.vue')
        case false:
          return import('../views/Master/MyTenders.vue')

        default:
          return MainPage
      }
    },
    beforeEnter: (to, from, next) => {
      if (!isLogged()) next('/')
      next()
    }
  },

  {
    path: '/tender/redact/:uuid',
    name: 'RedactTender',
    props: true,
    component: () => import('../views/Customer/RedactTender.vue'),
    beforeEnter: (to, from, next) => {
      if (!isLogged() || !isCustomer()) next('/') // TODO
      next()
    }
  },

  {
    path: '/create_tender',
    name: 'CreateTender',
    component: () => {
      switch (isCustomer()) {
        case true:
          return import('../views/Customer/CreateTender.vue')
        case false:
          return import("../views/Auth/RegistrationCustomer.vue");
        default:
          return MainPage
      }
    },
    beforeEnter: (to, from, next) => {
      if (!isLogged()) next('/login')
      next()
    }
  },

  {
    path: '/support',
    name: 'Support',
    component: () => import('../views/Customer/Support.vue'),
    beforeEnter: (to, from, next) => {
      if (!isLogged() || !isCustomer()) next('/') // TODO
      next()
    }
  },

  {
    path: '/tariff',
    name: 'Tariff',
    props: true,
    component: () => import('../views/Master/Tariff.vue'),
    beforeEnter: (to, from, next) => {
      if (!isLogged() || !isMaster()) next('/') // TODO
      next()
    }
  },

  // "/payment_history"

  {
    path: '*',
    meta: {
      layout: 'main'
    },
    component: ErrorPage
  }
]

export default routes
