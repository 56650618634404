import { getBearer } from '@/helpers/authHelper'

export const masterPortfolioUUIDStore = {
  state: {
    masterPortfolioUUID: {},
    isMasterPortfolioUUIDLoaded: false
  },
  getters: {
    getMasterPortfolioUUID (state) {
      return state.masterPortfolioUUID
    },
    getMasterPortfolioUUIDLoaded (state) {
      return state.isMasterPortfolioUUIDLoaded
    }
  },
  mutations: {
    setMasterPortfolioUUID (state, payload) {
      state.masterPortfolioUUID = payload
      state.isMasterPortfolioUUIDLoaded = true
    }
  },

  actions: {
    async setMasterPortfolioUUID (state, condition) {
      const url =
        process.env.VUE_APP_SERVER_URL + `/portfolios/${condition}`
      await fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: getBearer()
        }
      })
        .then((response) => response.json())
        .then((res) => {
          state.commit('setMasterPortfolioUUID', res.data)
        })
        .catch((e) => {
          console.log(e)
          state.commit('setMasterPortfolioUUID', {})
        })
    }
  }
}
