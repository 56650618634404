<template>
  <div>
    <div v-if="width >= 600" class="app-enter-services-range">
      <label class="app-enter-services-header">Полный спектр услуг</label>
      <div v-if="width > 992" class="app-enter-services-service-table">
        <div class="app-enter-services-card-service">
          <p @click="searchTenders(3)">Реставрация мягкой мебели</p>
          <p @click="searchTenders(15)">Реставрация паркета</p>
          <p @click="searchTenders(17)">Реставрация аллюминиевых окон</p>
          <p @click="searchTenders(16)">Реставрация деревянных окон</p>
        </div>
        <div class="app-enter-services-card-service">
          <p @click="searchTenders(19)">Золочение поталью</p>
          <p @click="searchTenders(1)">Окрашивание эмалью</p>
          <p @click="searchTenders(22)">Покрытие изделий лаком</p>
          <p @click="searchTenders(40)">Тонировка мебели</p>
        </div>
        <div class="app-enter-services-card-service">
          <p @click="searchTenders(21)">Изготовление мягкой мебели</p>
          <p @click="searchTenders(32)">Изготовление деревянной мебели</p>
          <p @click="searchTenders(45)">Изготовление лестниц</p>
          <p @click="searchTenders(44)">Изготовление пианино</p>
        </div>
        <div class="app-enter-services-card-service">
          <p @click="searchTenders(43)">Переклейка стула</p>
          <p @click="searchTenders(42)">Обивка тканью</p>
          <p @click="searchTenders(41)">Циклевка паркета</p>
          <p @click="searchTenders(31)">Циклевка шкафа</p>
        </div>
      </div>

      <div v-if="width <= 992" class="app-enter-services-service-table">
        <div class="app-enter-services-card-service">
          <p @click="searchTenders(3)">Реставрация мягкой мебели</p>
          <p @click="searchTenders(15)">Реставрация паркета</p>
          <p @click="searchTenders(17)">Реставрация аллюминиевых окон</p>
          <p @click="searchTenders(16)">Реставрация деревянных окон</p>
          <p @click="searchTenders(19)">Золочение поталью</p>
          <p @click="searchTenders(1)">Окрашивание эмалью</p>
        </div>
        <div class="app-enter-services-card-service">
          <p @click="searchTenders(22)">Покрытие изделий лаком</p>
          <p @click="searchTenders(40)">Тонировка мебели</p>
          <p @click="searchTenders(21)">Изготовление мягкой мебели</p>
          <p @click="searchTenders(32)">Изготовление деревянной мебели</p>
          <p @click="searchTenders(45)">Изготовление лестниц</p>
          <p @click="searchTenders(44)">Изготовление пианино</p>
        </div>

        <div class="app-enter-services-card-service">
          <p @click="searchTenders(43)">Переклейка стула</p>
          <p @click="searchTenders(42)">Обивка тканью</p>
          <p @click="searchTenders(41)">Циклевка паркета</p>
          <p @click="searchTenders(31)">Циклевка шкафа</p>
        </div>
      </div>

      <label class="app-enter-services-header-work">Как все работает</label>
      <div class="how-to-work">
        <div class="how-to-work-card">
          <div class="circle">1</div>
          <div class="stady-servece-image">
            <img src="./image/CreateTender.svg" />
          </div>
          <div class="description-stadies_wrap">
            <div class="description-stadies">
              Заказчик выбирает мастера,и начинается работа
            </div>
          </div>
        </div>
        <div class="how-to-work-card">
          <div class="circle">2</div>
          <div class="stady-servece-image">
            <img src="./image/Dialogs.svg" />
          </div>
          <div class="description-stadies_wrap">
            <div class="description-stadies">
              Мастер находит заказ и отправляет предложение
            </div>
          </div>
        </div>
        <div class="how-to-work-card">
          <div class="circle">3</div>
          <div class="stady-servece-image">
            <img src="./image/ChooseMaster.svg" />
          </div>
          <div class="description-stadies_wrap">
            <div class="description-stadies">
              Заказчик выбирает мастера, и начинается работа
            </div>
          </div>
        </div>
        <div class="how-to-work-card">
          <div class="circle">4</div>
          <div class="stady-servece-image">
            <img src="./image/SuccessWork.svg" />
          </div>
          <div class="description-stadies_wrap">
            <div class="description-stadies">
              Заказчик получает выполненую работу, а масетр — клиента
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="width < 600" class="app-enter-services-range">
      <div class="app-enter-services-header">Полный спектр услуг</div>
      <div class="spectr-service-table-small">
        <div
          v-for="service in services"
          :key="service.id"
          class="spectr-service-table-item-small"
        >
          <div class="spectr-service-table-item-text-small" @click="searchTenders(service.id)">
            <div>{{ service.label }}</div>
            <img src="./image/vector.svg" />
          </div>
        </div>
      </div>
      <div @click="openAllServices" class="all-service-button-container">
        <a-button class="all-service-button"> {{ buttonText() }}</a-button>
      </div>
      <label class="header-block-how-work-small">Как все работает</label>
      <div class="how-to-work">
        <div class="how-to-work-card">
          <div class="circle">1</div>
          <div class="stady-servece-image">
            <img src="./image/CreateTender.svg" />
          </div>
          <div class="description-stadies_wrap">
            <div class="description-stadies">
              Заказчик выбирает мастера,и начинается работа
            </div>
          </div>
        </div>
        <div class="how-to-work-card">
          <div class="circle">2</div>
          <div class="stady-servece-image">
            <img src="./image/Dialogs.svg" />
          </div>
          <div class="description-stadies_wrap">
            <div class="description-stadies">
              Мастер находит заказ и отправляет предложение
            </div>
          </div>
        </div>
        <div class="how-to-work-card">
          <div class="circle">3</div>
          <div class="stady-servece-image">
            <img src="./image/ChooseMaster.svg" />
          </div>
          <div class="description-stadies_wrap">
            <div class="description-stadies">
              Заказчик выбирает мастера, и начинается работа
            </div>
          </div>
        </div>
        <div class="how-to-work-card">
          <div class="circle">4</div>
          <div class="stady-servece-image">
            <img src="./image/SuccessWork.svg" />
          </div>
          <div class="description-stadies_wrap">
            <div class="description-stadies">
              Заказчик получает выполненую работу, а масетр — клиента
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {isCustomer} from "@/helpers/authHelper";

export default {
  name: 'EnterServicesRange',
  components: {},
  created () {
    window.addEventListener('resize', this.updateWidth)
  },
  data () {
    return {
      width: window.innerWidth,
      isOpenAllServices: false,
      services: [
        {label: 'Реставрация мягкой мебели', id : 3},
        {label:'Реставрация паркета', id : 15},
        {label:'Реставрация аллюминиевых окон', id : 17},
        {label:'Реставрация деревянных окон', id : 16},
        {label:'Золочение поталью', id : 19},
        {label:'Окрашивание эмалью', id : 1},
        {label:'Покрытие изделий лаком', id : 22},
        {label:'Тонировка мебели', id : 40}
      ],
      sliceServices: [
        {label:'Реставрация мягкой мебели', id : 21},
        {label:'Реставрация паркета', id : 32},
        {label:'Реставрация аллюминиевых окон', id : 45},
        {label:'Реставрация деревянных окон', id : 44},
        {label:'Золочение поталью', id : 43},
        {label:'Окрашивание эмалью', id : 42},
        {label:'Покрытие изделий лаком', id : 41},
        {label:'Тонировка мебели', id : 31}
      ],
      allServices: [
        {label:'Реставрация мягкой мебели', id : 3},
        {label:'Реставрация паркета', id : 15},
        {label:'Реставрация аллюминиевых окон', id : 17},
        {label:'Реставрация деревянных окон', id : 16},
        {label:'Золочение поталью', id : 19},
        {label:'Окрашивание эмалью', id : 1},
        {label:'Покрытие изделий лаком', id : 22},
        {label:'Тонировка мебели', id : 40},
        {label:'Изготовление мягкой мебели', id : 21},
        {label:'Изготовление деревянной мебели', id : 32},
        {label:'Изготовление лестниц', id : 45},
        {label:'Изготовление пианино', id : 44},
        {label:'Переклейка стула', id : 43},
        {label:'Обивка тканью', id : 42},
        {label:'Циклевка паркета', id : 41},
        {label:'Циклевка шкафа', id : 31}
      ]
    }
  },
  methods: {
    updateWidth () {
      this.width = window.innerWidth
    },
    openAllServices () {
      if (this.isOpenAllServices) {
        this.services = this.sliceServices
        this.isOpenAllServices = false
      } else {
        this.services = this.allServices
        this.isOpenAllServices = true
      }
    },
    buttonText () {
      if (this.isOpenAllServices) {
        return '^'
      } else {
        return 'Все услуги'
      }
    },
    searchTenders (tag) {
      const query = `tags=${tag}`;
      if (isCustomer()) {
        this.$router.push(`/main?${query}`)
      } else {
        this.$router.push(`/tenders?${query}`)
      }
    },
  }
}
</script>

<style lang="scss">
.app-enter-services-range {
  margin-left: 15%;
  margin-right: 15%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding-bottom: 20px;
  background-color: #ffffff;
}
.app-enter-services-header {
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin: 70px 0px 40px;
}

@media (max-width: 992px) {
  .app-enter-services-range {
    margin-left: 10%;
    margin-right: 10%;
  }
}

@media (max-width: 600px) {
  .app-enter-services-range {
    margin-left: 5%;
    margin-right: 5%;
    justify-content: center;
  }
  .app-enter-services-header {
    margin: 209px 0px 40px;
    font-size: 32px;
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
  }
}

.app-enter-services-service-table {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.app-enter-services-card-service {
  padding-right: 40px;
  p {
    cursor: pointer;
    &:hover {
      color: #b12021;
    }
  }
}

.app-enter-services-header-work {
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin: 40px 0px 50px;
}

.all-service-button-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0px 60px;
}
.all-service-button {
  width: 50%;
  height: 45px;
  padding: 5px 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px #b12021 solid;
  background-color: white;
  color: #b12021;
}

.how-to-work {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.how-to-work-card {
  /* padding-right: 40px; */
  width: 20%;
  margin-bottom: 40px;
}

.header-block-small {
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  margin: 70px 0px 40px;
}

.header-block-how-work-small {
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin: 10px 0px 50px;
}

.circle {
  height: 28px;
  width: 28px;
  background-color: #f7e9e9;
  border-radius: 50%;
  color: #b12021;
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 3px;
  margin-bottom: 10px;
  margin-left: 25%;
}

.description-stadies_wrap{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.description-stadies {
  max-width: 215px;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.stady-servece-image {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-bottom: 10px;
}
.spectr-service-table-item-small {
  border-top: 1px solid #979797;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.spectr-service-table-item-small :hover {
  background-color: #f7e9e9;
}
.spectr-service-table-item-text-small {
  margin: 10px 0px 10px 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}
.spectr-service-table-small {
  border-bottom: 1px solid #979797;
}

@media (max-width: 1200px) {
  .circle {
    margin-left: 15%;
  }
  .app-enter-services-card-service{
    padding-right: 20px;
  }
}

@media (max-width: 992px) {
  .how-to-work-card {
    width: 40%;
  }
}

@media (max-width: 600px) {
  .how-to-work-card {
    width: 40%;
  }
}

@media (max-width: 320px) {
  .how-to-work-card {
    /* padding-right: 40px; */
    width: 100%;
    margin-bottom: 30px;
  }
}
</style>
