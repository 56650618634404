<template>
  <div>
    <MainHeader></MainHeader>
    <router-view />
  </div>
</template>

<script>
import MainHeader from '@/components/Headers/MainHeader.vue'

export default {
  components: {
    MainHeader
  }
}
</script>
