<template>
  <div class="our__works">
    <div class="our__works__container">
      <div class="our__works__header">
        <div class="our__works__header__main__label">Наши тендеры</div>
        <div class="our__works__header__buttons">
          <div :class="active == '' ? 'our__works__menu__button active' : 'our__works__menu__button'" @click="setImage('')">Все</div>
          <div :class="active == 'restavration' ? 'our__works__menu__button active' : 'our__works__menu__button'" @click="setImage('restavration')">Реставрация</div>
          <div :class="active == 'painting' ? 'our__works__menu__button active' : 'our__works__menu__button'" @click="setImage('painting')">Покраска</div>
          <div :class="active == 'creation' ? 'our__works__menu__button active' : 'our__works__menu__button'" @click="setImage('creation')">Изготовление</div>
          <div :class="active == 'other' ? 'our__works__menu__button active' : 'our__works__menu__button'" @click="setImage('other')">Другое</div>
        </div>
      </div>
      <div class="our__works__carousel">
        <VueSlickCarousel
          ref="carousel"
          :arrows="false"
          :responsive="responsive"
          :slidesToShow="4"
          :slidesToScroll="1"
          :autoplay="true"
        >
          <div v-for="card in cardsCom" :key="card.id">
            <div class="vue__slick__carousel__card">
              <div
                :style="`background-image: url('${card.name}')`"
                class="vue__slick__carousel__item"
              ></div>
              <div class="vue__slick__carousel__type">{{ card.type }}</div>
              <div class="vue__slick__carousel__status">
                Статус: <span>{{ card.status }}</span>
              </div>
            </div>
          </div>
        </VueSlickCarousel>
        <div class="our__works__leaf__through__buttons">
          <img
            @click="prev"
            class="our__works__leaf__through__button__left"
            src="@/assets/image/arrow.svg"
          />
          <img
            @click="next"
            class="our__works__leaf__through__button__right"
            src="@/assets/image/arrow.svg"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import {cards, creation, painting, restavration, other} from "@/views/MainPage/cards";

export default {
  name: 'OurTenders',
  components: {
    VueSlickCarousel
  },
  data () {
    return {
      responsive: [
        {
          breakpoint: 1720,
          speed: 5,
          autoplay: true,
          settings: {
            centerPadding: '23%'
          }
        },
        {
          breakpoint: 1320,
          settings: {
            centerPadding: '7%'
          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            centerPadding: '15%'
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            centerPadding: '5%'
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1.5,
            slidesToScroll: 1
          }
        }
      ],
      active: '',
      cardsCom: cards
    }
  },
  methods: {
    next () {
      this.$refs.carousel.next()
    },
    prev () {
      this.$refs.carousel.prev()
    },
    setImage(value) {
      switch (value) {
        case 'restavration':
          this.active = 'restavration'
          this.cardsCom = restavration
          return;
        case 'painting':
          this.active = 'painting'
          this.cardsCom = painting
          return  ;
        case 'creation':
          this.active = 'creation'
          this.cardsCom = creation
          return  ;
        case 'other':
          this.active = 'other'
          this.cardsCom = other
          return  ;
        default:
          this.active = ''
          this.cardsCom = cards
          return  ;
      }
    }
  }
}
</script>

<style lang="scss">
.vue__slick__carousel {
  &__card {
    margin: 0px 20px;
    height: 200px;
    background-size: cover;
    background-position: center;
  }
  &__item {
    height: 200px;
    background-size: cover;
    background-position: center;
  }
  &__type {
    margin-top: 17px;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
  &__status {
    margin-top: 12px;
    span {
      color: #7fb56c;
    }
  }
}

.our__works {
  width: 100%;
  height: 674px;

  display: flex;
  flex-direction: column;
  align-content: center;

  background-color: #e5e5e5;

  &__carousel {
    margin: 64px -20px 0px;
  }

  &__container {
    margin-left: 15%;
    margin-right: 15%;
  }
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-top: 130px;

    &__main__label {
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 22px;
    }
    &__buttons {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &__menu__button {
    padding-right: 15px;
    color: #979797;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      color: #b12021;
    }
    &.active{
      color: #b12021;
    }
  }
  &__leaf__through {
    &__buttons {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin-right: 20px;
      margin-top: 37px;
    }
    &__button {
      &__left {
        margin-right: 12px;
      }
      &__right {
        transform: rotate(180deg);
      }
    }
  }
}

.slick__photos__item {
  height: 200px;
  width: 200px;
}

@media (max-width: 992px) {
  .our__works {
    &__container {
      margin-left: 10%;
      margin-right: 10%;
    }
  }
}

@media (max-width: 600px) {
  .our__works {
    &__container {
      margin-left: 5%;
      margin-right: 5%;
    }
    &__header {
      flex-direction: column;
      margin-left: 5%;
      margin-right: 5%;
      margin-top: 60px;
      justify-content: center;
      align-items: center;
      &__main__label {
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 22px;
        margin-bottom: 40px;
      }

      &__buttons {
        width: 100%;
      }
    }
  }
}

@media (max-width: 480px) {
}

@media (max-width: 320px) {
}
</style>
