import { getBearer } from '@/helpers/authHelper'

export const messagesStore = {
  state: {
    messages: [],
    messagesLoaded: false
  },
  getters: {
    getMessages (state) {
      return state.messages
    },
    getMessagesLoaded (state) {
      return state.messagesLoaded
    }
  },
  mutations: {
    setMessages (state, payload) {
      state.messages = payload
      state.messagesLoaded = true
    }
  },
  actions: {
    async setMessages (state, condition) {
      const url = process.env.VUE_APP_SERVER_URL + `/messages/${condition.tenderUUID}/${condition.userUUID}`
      await fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: getBearer()
        }
      })
        .then((response) => response.json())
        .then((res) => {
          state.commit('setMessages', res.data)
        })
        .catch((e) => {
          console.log(e)
          state.commit('setMessages', [])
        })
    }
  }
}
