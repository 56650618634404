<template>
  <div>
    <PersonalHeader />
    <div class="container full">
      <a-layout>
        <a-layout-content>
          <div class="empty-main">
            <router-view />
          </div>
        </a-layout-content>
        <div class="layout__empty__footer">
          <LinkFooter />
        </div>
      </a-layout>
    </div>
  </div>
</template>

<script>
import LinkFooter from '@/components/Footers/LinkFooter.vue'
import PersonalHeader from '@/components/Headers/PersonalHeader.vue'

export default {
  components: {
    LinkFooter,
    PersonalHeader
  }
}
</script>

<style lang="scss" scoped>

.ant-layout{
  height: 100%;
  background-color: #252525;
}
.empty-main {
  background-color: #252525;
  display: flex;
  padding: 20px;

  align-items: center;
  justify-content: center;
  align-content: center;
  height: 100%;
  min-height: 812px;
}

.container {
  /* height: 46px; */
  background-color: #252525;
  &.full{
    min-height: calc(100vh - 46px);
    height: 100%;
  }
}
.layout__empty__footer {
  padding: 0 15%;
  background-color: #252525;
  position: fixed;
  bottom: 0;
  display: flex;
  width: 100%;
}

@media (max-width: 992px) {
  .layout__empty__footer {
    padding: 0 10%;
  }
}

@media (max-width: 600px) {
  .layout__empty__footer {
    padding: 0 5%;
  }
}
</style>
