import { getBearer } from '@/helpers/authHelper'

export const responsesStore = {
  state: {
    tenderResponses: [],
    response: {},
    tenderResponsesLoaded: false,
    responseLoaded: false
  },
  getters: {
    getTenderResponses (state) {
      return state.tenderResponses
    },
    getResponse (state) {
      return state.response
    },
    getTenderResponsesLoaded (state) {
      return state.tenderResponsesLoaded
    },
    getResponseLoaded (state) {
      return state.responseLoaded
    }
  },
  mutations: {
    setTenderResponses (state, payload) {
      state.tenderResponses = payload
      state.tenderResponsesLoaded = true
    },
    setResponse (state, payload) {
      state.response = payload
      state.responseLoaded = true
    }
  },
  actions: {
    async setTenderResponses (state, condition) {
      const url = process.env.VUE_APP_SERVER_URL + `/tender-requests/${condition}`
      await fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: getBearer()
        }
      })
        .then((response) => response.json())
        .then((res) => state.commit('setTenderResponses', res.data.data))
        .catch((e) => {
          console.log(e)
          state.commit('setTenderResponses', 'Responses не загружены')
        })
    },
    async setResponse (state, condition) {
      const url = process.env.VUE_APP_SERVER_URL + `/requests/${condition}`
      await fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: getBearer()
        }
      })
        .then((response) => response.json())
        .then((res) => state.commit('setResponse', res.data))
        .catch((e) => {
          console.log(e)
          state.commit('setResponse', 'Responses не загружены')
        })
    }
  }
}
