<template>
  <a-config-provider :locale="ru_RU">
    <component :is="layout">
      <router-view />
    </component>
  </a-config-provider>
</template>

<script>
// eslint-disable-next-line camelcase
import ru_RU from 'ant-design-vue/lib/locale-provider/ru_RU'
import { isCustomer } from '@/helpers/authHelper'

import EmptyLayout from './layouts/EmptyLayout.vue'
import CustomerLayout from './layouts/CustomerLayout.vue'
import MasterLayout from './layouts/MasterLayout.vue'
import MainLayout from './layouts/MainLayout.vue'
import '../src/assets/themes/result.css'
import '../src/assets/themes/app-style-changes.css'
import '../src/assets/themes/buttons.css'

export default {
  components: {
    EmptyLayout,
    CustomerLayout,
    MainLayout,
    MasterLayout
  },
  computed: {
    layout () {
      return (
        (this.$route.meta.layout || (isCustomer() ? 'customer' : 'master')) +
        '-layout'
      )
    }
  },
  data () {
    return {
      ru_RU
    }
  }
}
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap");
</style>

