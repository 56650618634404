import axios from "axios";

export const geoLocationStore = {
  state: {
    geoLocation: '',
    cities: [],
    geoLoaded: false
  },
  getters: {
    getGeoLocation (state) {
      return state.geoLocation
    },
    getCities (state) {
      return state.cities
    }
  },
  mutations: {
    setGeoLocation (state, payload) {
      state.geoLocation = payload
    },
    setCities (state, payload) {
      state.cities = payload
    }
  },
  actions: {
    async setGeoLocation (state, condition) {
      if (condition === '') {
        const url =
          'https://suggestions.dadata.ru/suggestions/api/4_1/rs/iplocate/address?ip='
        const token = 'b69b2b53913ad730f068eee3356a935af3363265'
        await fetch(url, {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Token ' + token
          }
        })
          .then((response) => response.json())
          .then((res) => state.commit('setGeoLocation', res.location.value))
          .catch((e) => {
            console.log(e)
            state.commit('setGeoLocation', 'Не найден')
          })
      } else {
        state.commit('setGeoLocation', condition)
      }
    },
    async setCities (state, condition) {
      if (condition) {
        const url = process.env.VUE_APP_SERVER_URL + '/cities'
        await fetch(url, {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        })
          .then((response) => response.json())
          .then((res) => {
            if (res.data) {
              let itemsMap = new Map();
              res.data.forEach((item) => {
                itemsMap.set(item.name, item.id)
              });
              // todo use array
              const array = [...itemsMap].map(([name, value]) => ({ name, value }));

              state.commit('setCities', array)
            }

          })
          .catch((e) => {
            console.log(e, 'e')
            state.commit('setCities', [])
          })
      } else {
        state.commit('setCities', condition)
      }
    }
  },
  modules: {}
}
