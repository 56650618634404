<template>
  <div :class="isEntry ? `personal__header__entry` : `personal__header`">
    <router-link to="/main">
      <div>
        <img @click="routeToMainPage" src="./svg/EnterLogo.svg" />
      </div>
    </router-link>
    <div v-show="!isLogged" class="login-buttons">
      <img src="@/assets/image/person.svg" />
      <label class="login-button">Вход</label>
    </div>
    <div v-show="isLogged" class="login-buttons">
      <router-link v-if="width >= 768" v-show="isCustomer" to="/create_tender">
        <a-button class="create-tender-button">Создать тендер</a-button>
      </router-link>
      <a-dropdown>
        <template #overlay>
          <a-menu>
            <a-menu-item key="1">
              <router-link to="/set_profile">
                Профиль
              </router-link>
            </a-menu-item>
            <a-menu-item key="2">
              <router-link to="/tenders">
                Тендеры
              </router-link>
            </a-menu-item>
            <a-menu-item @click="confirm" key="3">
              Выход
            </a-menu-item>
          </a-menu>
        </template>
        <div
            v-if="avatar"
            class="main__header__personal__avatar__container"
        >
          <img
              class="main__header__personal__avatar"
              :src="serverPath + avatar"
          />
          <label class="main__header__login__button">{{
              personName
            }}</label>
        </div>
        <div
            v-if="!avatar"
            class="main__header__personal__avatar__container"
        >
          <img src="./svg/person.svg" />
          <label class="main__header__login__button">{{
              personName
            }}</label>
        </div>
      </a-dropdown>
    </div>
  </div>
</template>

<script>
import {
  isLogged,
  getPersonName,
  getPersonAvatar,
  logout
} from '@/helpers/authHelper'
import throttle from 'lodash/throttle'

export default {
  props: ['isCustomer', 'isEntry'],
  name: 'PersonalHeader',
  data () {
    return {
      serverPath: process.env.VUE_APP_SERVER_URL_FILE,
      width: window.innerWidth
    }
  },
  computed: {
    personName () {
      return getPersonName()
    },
    avatar () {
      return getPersonAvatar()
    },
    isLogged () {
      return isLogged()
    }
  },
  beforeMount () {
    window.addEventListener('resize', this.updateWidth)
  },
  methods: {
    updateWidth: throttle(function (e) {
      this.width = window.innerWidth
    }, 500),
    routeToMainPage () {
      this.$router.push('/')
    },
    confirm (e) {
      console.log(e)
      logout()
    },
    cancel (e) {
      console.log(e)
    }
  }
}
</script>

<style lang="scss" scoped>
.personal__header {
  background-color: #f6f6f6;
  height: 46px;
  display: flex;
  flex-direction: row;
  padding: 0 15%;
  align-items: center;
  justify-content: space-between;

  &__entry {
    background-color: #f6f6f6;
    height: 46px;
    display: flex;
    flex-direction: row;
    padding: 0 15% 0 5%;
    align-items: center;
    justify-content: space-between;
  }
}

@media (max-width: 992px) {
  .personal__header {
    padding: 0 10%;
  }
}

@media (max-width: 769px) {
  .personal__header {
    &__entry {
      padding: 0 15% 0 15px;
    }
  }
}

@media (max-width: 600px) {
  .personal__header {
    padding: 0 5%;
  }
}

.login-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login-button {
  margin-left: 15px;
  cursor: pointer;
}
.create-tender-button {
  height: 32px;
  width: 157px;

  color: white;
  background-color: #b12021;
  border-radius: 3px;

  margin-right: 19px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}

.header-personal-avatar {
  height: 24px;
  width: 24px;
  border-radius: 50%;
}
</style>
