import { getBearer } from '@/helpers/authHelper'

export const masterTendersStatusThreeStore = {
  state: {
    masterTendersStatusThree: [],
    masterTendersStatusThreeTotal: 0,
    masterTendersStatusThreeLoaded: false
  },
  getters: {
    getMasterTendersStatusThree (state) {
      return state.masterTendersStatusThree
    },

    getMasterTendersStatusThreeTotal (state) {

      return state.masterTendersStatusThreeTotal
    },

    getMasterTendersStatusThreeLoaded (state) {
      return state.masterTendersStatusThreeLoaded
    }
  },
  mutations: {
    setMasterTendersStatusThree (state, payload) {
      console.log(payload.data)
      state.masterTendersStatusThree = payload.data
      state.masterTendersStatusThreeTotal = payload.total
      state.masterTendersStatusThreeLoaded = true
    }
  },

  actions: {
    async setMasterTendersStatusThree (state, condition) {
      if (condition) {
        const url =
          process.env.VUE_APP_SERVER_URL +
          `/user-history?page=${condition}`
        await fetch(url, {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: getBearer()
          }
        })
          .then((response) => response.json())
          .then((res) => {
            state.commit('setMasterTendersStatusThree', res.data)
          })
          .catch((e) => {
            console.log(e)
            state.commit('setMasterTendersStatusThree', [])
          })
      } else {
        state.commit('setMasterTendersStatusThree', [])
      }
    }
  }
}
