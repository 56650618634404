import Vue from 'vue'
import Vuex from 'vuex'

import { customerTendersStatusOneStore } from './customerTendersStatusOne'
import { customerTendersStatusTwoStore } from './customerTendersStatusTwo'
import { customerTendersStatusThreeStore } from './customerTendersStatusThree'
import { masterTendersStatusOneStore } from './masterTendersStatusOne'
import { masterTendersStatusTwoStore } from './masterTendersStatusTwo'
import { masterTendersStatusThreeStore } from './masterTendersStatusThree'
import { geoLocationStore } from './geoLocation'
import { tagsStore } from './tags'
import { personalData } from './personalData'
import { tendersStore } from './tenders'
import { responsesStore } from './responses'
import { messagesStore } from './messages'
import { masterProfileStore } from './masterProfile'
import { masterPortfolioStore } from './masterPortfolio'
import { masterReviewsStore } from './masterReviews'
import { masterPortfolioUUIDStore } from './masterPortfolioUUID'
import createPersistedState from 'vuex-persistedstate'
import { masterAlreadyRespondedStore } from './masterAlreadyResponded'
import { ordersStore } from "@/store/orders";

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ['geo.geoLocation', 'tendersStore.tender']
    })
  ],
  state: {},
  mutations: {},
  actions: {},
  modules: {
    customerTendersStatusOneStore,
    customerTendersStatusTwoStore,
    customerTendersStatusThreeStore,
    geo: geoLocationStore,
    masterTendersStatusOneStore,
    masterTendersStatusTwoStore,
    masterTendersStatusThreeStore,
    messagesStore,
    masterProfileStore,
    masterReviewsStore,
    masterPortfolioStore,
    masterPortfolioUUIDStore,
    masterAlreadyRespondedStore,
    ordersStore,
    personalData,
    responsesStore,
    tags: tagsStore,
    tendersStore
  }
})
