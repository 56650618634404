import router from '@/router'

export const getAuthData = () => {
  const data = localStorage.loginData || sessionStorage.loginData
  return data && JSON.parse(data)
}

export const isRemember = () => {
  return (getAuthData() && getAuthData().isRemember ? getAuthData().isRemember : undefined)
}

export const getToken = () => {
  return (getAuthData() && getAuthData().token ? getAuthData().token : undefined)
}

export const getUUID = () => {
  const data = localStorage.loginData || sessionStorage.loginData
  const parseData = JSON.parse(data)
  const uuid = parseData.uuid ? parseData.uuid : undefined
  return uuid
}

export function getBearer () {
  return 'Bearer ' + getToken()
}

export const isLogged = () => {
  return Boolean(getToken())
}

export const getPersonName = () => {
  return (getAuthData() && getAuthData().name ? getAuthData().name : undefined)
}

export const getPersonAvatar = () => {
  return (getAuthData() && getAuthData().image ? getAuthData().image : undefined)
}

export const logout = () => {
  localStorage.removeItem('loginData')
  sessionStorage.removeItem('loginData')
  router.push('/')
  router.go()
}

export const login = (data, remember) => {
  if (remember) {
    localStorage.loginData = JSON.stringify(data)
  } else {
    sessionStorage.loginData = JSON.stringify(data)
  }
}

export const isMaster = () => {
  if (getAuthData()) {
    if (getAuthData().userType === 'user') {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

export const isCustomer = () => {
  if (getAuthData()) {
    if (getAuthData().userType === 'customer') {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

export const userType = () => {
  if (getAuthData()) {
    return getAuthData().userType
  } else {
    return 'empty'
  }
}
