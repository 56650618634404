import { getBearer } from '@/helpers/authHelper'

export const masterReviewsStore = {
  state: {
    masterReviews: {},
    isMasterReviewsLoaded: false
  },
  getters: {
    geMasterReviews (state) {
      return state.masterReviews
    },

    getMasterReviewsLoaded (state) {
      return state.isMasterReviewsLoaded
    }
  },
  mutations: {
    setMasterReviews (state, payload) {
      state.masterReviews = payload
      state.isMasterReviewsLoaded = true
    }
  },

  actions: {
    async setMasterReviews (state) {
      const url = process.env.VUE_APP_SERVER_URL + '/reviews'
      await fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: getBearer()
        }
      })
        .then((response) => response.json())
        .then((res) => {
          state.commit('setMasterReviews', res.data)
        })
        .catch((e) => {
          console.log(e)
          state.commit('setMasterReviews', {})
        })
    }
  }
}
