import { getBearer } from '@/helpers/authHelper'

export const customerTendersStatusOneStore = {
  state: {
    customerTendersStatusOne: [],
    customerTendersStatusOneTotal: 0,
    customerTendersStatusOneLoaded: false
  },
  getters: {
    getCustomerTendersStatusOne (state) {
      return state.customerTendersStatusOne
    },
    getCustomerTendersStatusOneTotal (state) {
      return state.customerTendersStatusOneTotal
    },
    getCustomerTendersStatusOneLoaded (state) {
      return state.customerTendersStatusOneLoaded
    }
  },
  mutations: {
    setCustomerTendersStatusOne (state, payload) {
      state.customerTendersStatusOne = payload.data
      state.customerTendersStatusOneTotal = payload.total + 5
      state.customerTendersStatusOneLoaded = true
    }
  },
  actions: {
    async setCustomerTendersStatusOne (state, condition) {
      if (condition) {
        const url =
          process.env.VUE_APP_SERVER_URL +
          `/customer-my-tenders?page=${condition}`
        fetch(url, {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: getBearer()
          }
        })
          .then((response) => response.json())
          .then((res) => {
            state.commit('setCustomerTendersStatusOne', res.data)
          })
          .catch((e) => {
            console.log(e)
            state.commit('setCustomerTendersStatusOne', [])
          })
      } else {
        state.commit('setCustomerTendersStatusOne', [])
      }
    }
  }
}
