<template>
  <div class="main__page">
    <div class="main__page__enter__container">
      <div
        v-if="width < 600"
        class="main__page__enter__container__small__header"
      >
        Поможем найти мастера для любой задачи
      </div>
      <div v-if="width < 600 && !master" class="main__page__enter__container__small">
        <div class="main__page__enter__container__small__body">
          <div class="main__page__enter__container__small__inputs">
            <a-select
              show-search
              size="large"
              class="main__page__enter__container__small__input"
              style="margin-bottom: 27px"
              placeholder="Услуги или мастер"
            >
              <a-select-option v-for="i in getSubtags()" :key="i.id" :value="i.name" @click="choseSubtags(i.id)">
                {{ i.name }}
              </a-select-option>
            </a-select>

            <a-select
              show-search
              size="large"
              label-in-value
              class="main__page__enter__container__small__input__city"
              style="margin-bottom: 27px"
              placeholder="Москва,Россия"
            >
              <a-select-option v-for="i in getCities()" :key="i.name"  @click="choseCity(i.name)">
                {{ i.name }}
              </a-select-option>
            </a-select>
          </div>

          <Button disabled="" @click="searchTenders()" buttonClass="red-antd-button" style="height: 40px"
            > {{isLog ? 'Создать' : 'Найти'}}</Button
          >
        </div>
      </div>

      <div v-if="width >= 600 && !master" class="main__page__enter__container__big">
        <div class="main__page__enter__container__big__header">
          Поможем найти мастера для любой задачи
        </div>

        <div class="main__page__enter__container__big__body">
          <div class="main__page__enter__container__big__inputs">
            <a-select
              show-search
              size="large"
              class="main__page__enter__container__big__input"
              placeholder="Услуги или мастер"
            >
              <a-select-option v-for="i in getSubtags()"
                               :key="i.id"
                               @click="choseSubtags(i.id)"
                               :value="i.name">
                {{ i.name }}
              </a-select-option>
            </a-select>

            <a-select
              show-search
              size="large"
              label-in-value
              :class="`main__page__enter__container__big__input__city`"
              placeholder="Москва,Россия"
            >
              <a-select-option v-for="i in getCities()" :key="i.name" @click="choseCity(i.name)">
                {{ i.name }}
              </a-select-option>
            </a-select>
          </div>
          <Button

              @click="searchTenders()"
              buttonClass="red-antd-button"
              style="width: 20%; height: 40px"
            >{{isLog ? 'Создать' : 'Найти'}}</Button
          >
        </div>
      </div>
    </div>
    <ServicesRange></ServicesRange>
    <OurTenders></OurTenders>
    <WorkWithUs v-show="!isLog" ></WorkWithUs>
    <MainFooter></MainFooter>
  </div>
</template>

<script>
import ServicesRange from '@/views/MainPage/ServicesRange.vue'
import OurTenders from '@/views/MainPage/OurTenders.vue'
import WorkWithUs from '@/views/MainPage/WorkWithUs.vue'
import MainFooter from '@/components/Footers/MainFooter.vue'
import Button from '@/components/common/Button'
import {isLogged, isCustomer, getAuthData, isMaster} from '@/helpers/authHelper'

export default {
  mounted () {
    this.$store.dispatch('setSubTags')
    this.$store.dispatch('setCities', true)
  },
  name: 'MainPage',
  components: {
    ServicesRange,
    OurTenders,
    WorkWithUs,
    MainFooter,
    Button
  },
  created () {
    window.addEventListener('resize', this.updateWidth)
  },
  data () {
    return {
      width: window.innerWidth,
      chosenCity: null,
      chosenTags: null,
    }
  },
  computed: {
    isLog () {
      return isLogged()
    },
    customer() {
      if (isCustomer()) {
        return true
      } else {
        return false
      }
    },
    master() {
      if (isMaster()) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    updateWidth () {
      this.width = window.innerWidth
    },
    getCities () {
      return this.$store.getters.getCities
    },
    getSubtags () {
      return this.$store.getters.getSubTags
    },
    searchTenders () {
      const query = ''
          + (this.chosenCity ? `city=${this.chosenCity}` : '')
          + (this.chosenCity && this.chosenTags ? '&' : '')
          + (this.chosenTags ? `tags=${this.chosenTags}` : '')
      if (isCustomer()) {
        this.$router.push(`/create_tender?${query}`)
      } else {
        this.$router.push(`/tenders?${query}`)
      }
    },
    choseCity (id) {
      this.chosenCity = id
    },
    choseSubtags (id) {
      this.chosenTags = id
    }
  }
}
</script>

<style lang="scss">
//@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed");

.main__page {
  &__enter__container {
    height: 100vh;
    width: 100%;
    background-image: url("./image/hero_bg_2.jpg");
    background-size: cover;
    background-position: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    &__small {
      margin-left: 5%;
      margin-right: 5%;
      background-color: #ffffff;
      position: relative;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      padding: 40px 5%;
      border-radius: 3px;
      box-shadow: 0px 4px 8px 0px rgba(34, 60, 80, 0.2);

      &__header {
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: 0em;
        text-align: center;
        color: white;
        display: flex;
        margin-left: 5%;
        margin-right: 5%;
        padding-top: 40%;
      }

      &__body {
        display: flex;
        flex-direction: column;
      }
      &__inputs {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      &__input {
        width: 100%;
        margin-bottom: 24px;
        &__city {
          width: 100%;
          margin-bottom: 24px;
        }
      }
      &__button {
        height: 40px;
        width: 100%;
        background-color: #b12021;
        color: rgb(255, 255, 255);
      }
    }
    &__big {
      width: 70%;
      background-color: #ffffff;
      position: relative;
      top: 132px;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      padding: 40px 5%;
      border-radius: 3px;

      &__header {
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 20px;
      }

      &__body {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      &__inputs {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 75%;
        height: 50px;
      }
      &__input {
        width: 47%;
        &__city {
          width: 47%;
        }
      }
      &__button {
        width: 20%;
      }
    }
  }
}

@media (max-width: 1200px) {
  .main__page {
    &__enter__container {
      height: 550px;
      &__small {
      }
      &__big {
      }
    }
  }
}

@media (max-width: 992px) {
  .main__page {
    &__enter__container {
      height: 530px;

      &__big {
        width: 80%;
      }
    }
  }
}

@media (max-width: 600px) {
  .main__page {
    &__enter__container {
      height: 474px;
      flex-direction: column;
      &__small {
        top: 15%;
        width: 90%;
      }
    }
  }
}

@media (max-width: 480px) {
  .main__page {
    &__enter__container {
      height: 427px;
      &__small {
        top: 15%;
      }
    }
  }
}

@media (max-width: 320px) {
  .main__page {
    &__enter__container {
      height: 401px;
      &__small {
        top: 15%;
      }
    }
  }
}
</style>
