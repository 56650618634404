<template>
  <div class="layout__master_wrapper">
    <div class="layout__master">
      <a-layout>
        <PersonalHeader
            :isEntry="true"
            class="shadow__container__header"
            isLogged="true"
        />
        <a-layout id="layout-container-master" class="layout__master__container">
          <a-layout-sider
              class="layout__master__sider"
              breakpoint="md"
              v-model="collapsed"
              :trigger="null"
              collapsible
          >
            <div class="logo" />
            <a-menu
                :style="{ height: '100%', borderRight: 0 }"
                mode="inline"
                :default-selected-keys="[collapsed ? '1' : '2']"
            >
              <a-menu-item :title="null" v-if="width < 768" @click="changeCollapse" key="1">
                <a-icon
                    :type="collapsed ? 'menu-unfold' : 'menu-fold'"
                    :style="collapsed ? 'margin-left: 15px' : ''"
                />
              </a-menu-item>
              <a-menu-item @click="linkTo('/main')" key="2">
                <a-icon
                    type="home"
                    :style="collapsed ? 'margin-left: 15px' : ''"
                />
                <span> Главная</span>
              </a-menu-item>
              <a-menu-item @click="linkTo('/set_profile')" key="3">
                <a-icon
                    type="profile"
                    :style="collapsed ? 'margin-left: 15px' : ''"
                />
                <span>Профиль</span>
              </a-menu-item>
              <a-menu-item @click="linkTo('/my_tenders')" key="4">
                <a-icon
                    type="reconciliation"
                    :style="collapsed ? 'margin-left: 15px' : ''"
                />
                <!-- <MyRequestSVG></MyRequestSVG> -->
                <span>Мои Тендеры</span>
              </a-menu-item>
              <a-menu-item @click="linkTo('/tariff')" key="5">
                <a-icon
                    :style="collapsed ? 'margin-left: 15px' : ''"
                    type="dollar"
                />
                <span>Тарифный план</span>
              </a-menu-item>
              <a-menu-item key="6">
                <a-popconfirm
                    title="Выйти из приложения"
                    ok-text="Да"
                    cancel-text="Нет"
                    @confirm="exit"
                    @cancel="closeConfirm"
                    placement="bottomLeft"
                >
                  <div
                      :class="
                    collapsed
                      ? 'layout__master__exit__button collapsed'
                      : 'layout__customer__exit__button'
                  "
                  >
                    <a-icon type="import" />

                    <span>Выход</span>
                  </div>
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-layout-sider>

          <a-layout>
            <a-layout-content>
              <div class="layout__master__main__content">
                <router-view />
              </div>
            </a-layout-content>
          </a-layout>
        </a-layout>
      </a-layout>
    </div>
    <div class="layout__master__footer">
      <LinkFooter :isApp="true" />
    </div>
  </div>
</template>

<script>
import LinkFooter from '@/components/Footers/LinkFooter.vue'
import PersonalHeader from '@/components/Headers/PersonalHeader.vue'
import { logout } from '@/helpers/authHelper'
// import MyRequestSVG from "@/layouts/MyRequestSVG"

export default {
  components: {
    LinkFooter,
    PersonalHeader
    // MyRequestSVG,
  },
  data () {
    return {
      collapsed: false,
      width: window.innerWidth
    }
  },
  created () {
    window.addEventListener('resize', this.updateWidth)
  },
  methods: {
    updateWidth () {
      this.width = window.innerWidth
    },
    exit () {
      logout()
    },
    closeConfirm () {
      this.visiblePopconfirm = !this.visiblePopconfirm
    },
    changeCollapse () {
      this.collapsed = !this.collapsed
    },
    linkTo (link) {
      this.$router.push(link)
    }
  },
  computed: {
    isCollapsed () {
      if (this.collapsed) {
        return true
      }
      if (this.width < 992) {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="scss">
.shadow__container__header {
  box-shadow: 1px 8px 8px 0px rgba(34, 60, 80, 0.2);
  z-index: 11 !important;
}
.shadow__container {
  box-shadow: 1px 8px 8px 0px rgba(34, 60, 80, 0.2);
  z-index: 2 !important;
}
.layout__master {
  height: 100%;
  background: #f6f6f6;
  .ant-layout{
    height: 100%;
    background: none;
  }
  &_wrapper{
    height: 100%;
  }
  &__exit__button {
    height: 100%;
    width: 100%;
  }
  &__container {
    background: #f6f6f6;
    margin-left: 5%;
  }
  &__menu__item__link {
    display: flex;
  }
  &__sider {
    background-color: white;
    z-index: 9;
    box-shadow: 1px 4px 8px 0px rgba(34, 60, 80, 0.2);
    height: 90%;
  }
  &__footer {
    padding: 0 15%;
    background-color: #f6f6f6;
  }
  &__exit__button {
    &.collapsed {
      margin-left: 15px;
    }
  }
}

#layout-container-master {
  background: #f6f6f6;
}

.layout-content {
  width: 100%;
}
.ant-layout-sider {
  background: #ffffff;
}

@media (max-width: 992px) {
  .layout__master {
  }
}

@media (max-width: 768px) {
  .layout__master {
    &__container {
      margin-left: 0%;
    }
    &__sider {
      height: 100%;
    }
  }
}
</style>
