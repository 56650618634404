import { getBearer } from '@/helpers/authHelper'

export const customerTendersStatusTwoStore = {
  state: {
    customerTendersStatusTwo: [],
    customerTendersStatusTwoTotal: 0,
    customerTendersStatusTwoLoaded: false
  },
  getters: {
    getCustomerTendersStatusTwo (state) {
      return state.customerTendersStatusTwo
    },
    getCustomerTendersStatusTwoTotal (state) {
      return state.customerTendersStatusTwoTotal
    },

    getCustomerTendersStatusTwoLoaded (state) {
      return state.customerTendersStatusTwoLoaded
    }
  },
  mutations: {
    setCustomerTendersStatusTwo (state, payload) {
      state.customerTendersStatusTwo = payload.data
      state.customerTendersStatusTwoTotal = payload.total
      state.customerTendersStatusTwoLoaded = true
    }
  },
  actions: {
    async setCustomerTendersStatusTwo (state, condition) {
      if (condition) {
        const url =
          process.env.VUE_APP_SERVER_URL +
          `/customer-my-tenders-in-progress?page=${condition}`
        fetch(url, {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: getBearer()
          }
        })
          .then((response) => response.json())
          .then((res) => {
            state.commit('setCustomerTendersStatusTwo', res.data)
          })
          .catch((e) => {
            console.log(e)
            state.commit('setCustomerTendersStatusTwo', [])
          })
      } else {
        state.commit('setCustomerTendersStatusTwo', [])
      }
    }
  }
}
