<template>
  <a-button :disabled="disabled ? true : false" @click="click" :class="`antd-tender-button ${buttonClass}`">
    <slot></slot>
  </a-button>
</template>

<script>
export default {
  props: ['buttonClass', 'disabled'],
  methods: {
    click () {
      this.$emit('click')
    }
  }
}
</script>
<style scoped>
.antd-tender-button{
  max-width: 400px;
}
.red-antd-button {
  padding: 5px 16px;
  background-color: #b12021;
  color: white;
  border-radius: 3px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
}
.red-antd-button34 {
  padding: 5px 16px;
  background-color: #b12021;
  color: white;
  border-radius: 3px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 34px;
}

.white-antd-button {
  width: 100%;
  height: 40px;
  padding: 5px 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  background-color: white;
  color: #b12021;
}

.white-antd-button-height38 {
  width: 100%;
  height: 38px;
  padding: 5px 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  background-color: white;
  color: #b12021;
}

.white-antd-button-border {
  width: 100%;
  height: 40px;
  padding: 5px 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px #b12021 solid;
  background-color: white;
  color: #b12021;
}

.red-antd-button-without-width {
  padding: 5px 16px;
  background-color: #b12021;
  color: white;
  border-radius: 3px;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.white-antd-button-without-width {
  padding: 5px 16px;
  /* border: none; */
  background-color: white;
  color: #b12021;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
