import {getBearer} from "@/helpers/authHelper";

export const ordersStore = {
    state: {
        orders: [],
        ordersLoaded: false,
        ordersHistory: [],
        ordersLoadedHistory: false,
    },
    getters: {
        getOrders (state) {
            return state.orders
        },
        getOrdersLoaded (state) {
            return state.ordersLoaded
        },
        getOrdersHistory (state) {
            return state.ordersHistory
        },
        getOrdersLoadedHistory (state) {
            return state.ordersLoadedHistory
        }
    },
    mutations: {
        setOrders (state, payload) {
            state.orders = payload
            state.ordersLoaded = true
        },
        setOrdersHistory (state, payload) {
            state.ordersHistory = payload
            state.ordersLoadedHistory = true
        }
    },
    actions: {
        async setOrders (state) {
            const url = process.env.VUE_APP_SERVER_URL + '/active-orders'
            await fetch(url, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: getBearer()
                }
            })
                .then((response) => response.json())
                .then((res) => state.commit('setOrders', res.data))
                .catch((e) => {
                    console.log(e)
                    // state.commit('setOrders', 'Теги не загружены')
                })
        },
        async setOrdersHistory (state) {
            const url = process.env.VUE_APP_SERVER_URL + '/orders-history'
            await fetch(url, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: getBearer()
                }
            })
                .then((response) => response.json())
                .then((res) => state.commit('setOrdersHistory', res.data.data))
                .catch((e) => {
                    console.log(e)
                    // state.commit('setOrders', 'Теги не загружены')
                })
        }
    }
}
