<template>
  <div class="geo-modal-container">
    <div class="container-btn-close">
      <div class="btn-close" @click="close" aria-label="Close modal">
        <img src="@/assets/image/CloseIcon.svg" />
      </div>
    </div>
    <div class="content">
      <div class="content-main-label">Выбор города или региона</div>
      <a-form>
        <a-form-item :validateStatus="statusCityName" :help="helpCityName">
          <a-input
            class="geo-form-item-intput"
            v-bind:value="inputTextCityName"
            v-on:input="inputNameCity($event.target.value)"
            :placeholder="placeholder"
            size="large"
          />
        </a-form-item>
        <a-form-item>
          <div class="geo-cities-container">
            <div
              v-for="city in filterArray()"
              :key="city.id"
              class="city"
              @click="toushCityNameFromArray(city.name)"
            >
              {{ city.name }}
            </div>
          </div>
        </a-form-item>
      </a-form>

      <div class="content-btn-actions">
        <button class="content-btn-save" @click="addCity">Сохранить</button>
        <button class="content-btn-cancel" @click="close">Отмена</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GeoModal',
  mounted () {
    this.$store.dispatch('setCities', true)
  },
  components: {},
  methods: {
    close () {
      this.$emit('close')
    },
    convertCityName (str) {
      if (!str) return str
      return str[0].toUpperCase() + str.slice(1).toLocaleLowerCase()
    },

    addCity () {
      if (this.inputTextCityName === '') {
        this.statusCityName = 'error'
        this.helpCityName = 'Введите названия города'
        this.placeholder = 'Пустое поле!'
      } else {
        if (
          this.$store.getters.getCities.find(
            (city) => city.name === this.inputTextCityName
          )
        ) {
          this.$store.dispatch('setGeoLocation', this.inputTextCityName)
          this.$emit('close')
        } else {
          console.log('error find city')
          this.statusCityName = 'error'
          this.helpCityName = 'Введите правильное название города'
        }
      }
    },

    inputNameCity (value) {
      this.inputTextCityName = value
      this.statusCityName = 'success'
      this.helpCityName = ''
      this.placeholder = 'Ведите название города'
    },

    toushCityNameFromArray (name) {
      this.inputTextCityName = name
      this.statusCityName = 'success'
      this.helpCityName = ''
    },

    filterArray () {
      const constCities = this.$store.getters.getCities
      if (this.inputTextCityName === '') {
        return constCities.slice(0, 6)
      } else {
        const filterCities = this.$store.getters.getCities.filter((city) =>
          city.name.toLowerCase().match(this.inputTextCityName.toLowerCase())
        )

        return filterCities.slice(0, 6)
      }
    }
  },

  data: () => ({
    placeholder: 'Ведите название города',
    inputTextCityName: '',
    statusCityName: 'success',
    helpCityName: ''
  })
}
</script>

<style>
.geo-modal-container {
  height: 100%;
  width: 100%;
  border-radius: 3px;

  background: #ffffff;
  display: flex;
  flex-direction: column;
}

.container-btn-close {
  display: flex;
  padding: 10px;
  justify-content: flex-end;
  width: 100%;
}
.btn-close {
  width: 30px;
  height: 30px;
}

.content {
  flex-direction: column;

  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding-left: 33px;
  padding-right: 29px;
}
.content-main-label {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 22px;
  margin-bottom: 29px;
}

.geo-form-item {
  width: 100%;
  height: 50px;
}
.geo-form-item-intput {
  margin-top: 29px;
  width: 100%;
  height: 50px;
  border: 1px solid #d9d9d9;
}

.content-text-input::placeholder {
  color: #d9d9d9;
}

.geo-cities-container {
  margin-top: 34px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.city {
  background-color: #f7e9e9;
  color: #b12021;
  margin-right: 15px;
  border-radius: 3px;
  padding: 10px 8px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  text-align: center;
}
.content-btn-actions {
  margin-top: 50px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.content-btn-save {
  height: 42px;
  width: 123px;

  color: white;
  background-color: #b12021;

  border: 1px;
  border-radius: 3px;
  margin-right: 14px;
}

.content-btn-cancel {
  height: 42px;
  width: 123px;

  border: 1px solid #b12021;
  border-radius: 3px;

  color: #b12021;
  background-color: white;
}
</style>
