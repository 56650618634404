import { getBearer } from '@/helpers/authHelper'

export const personalData = {
  state: {
    personalData: {},
    personalDataStatusLoading: false
  },
  getters: {
    getPersonalData (state) {
      return state.personalData
    },
    getPersonalDataStatusLoading (state) {
      return state.personalDataStatusLoading
    }
  },
  mutations: {
    setPersonalData (state, payload) {
      state.personalData = payload
      state.personalDataStatusLoading = true
    }
  },
  actions: {
    async setPersonalData (state, condition) {
      const url = process.env.VUE_APP_SERVER_URL + `/me`
      await fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: getBearer()
        }
      })
        .then((response) => response.json())
        .then((res) => {
          state.commit('setPersonalData', res.data)
        })
        .catch((e) => {
          console.log(e)
          state.commit('setPersonalData', 'Персональные данные не загружены')
        })
    }
  },
  modules: {}
}
