export const tagsStore = {
  state: {
    tags: [],
    tagsLoaded: false,
    subTags: [],
    subTagsLoaded: false
  },
  getters: {
    getTags (state) {
      return state.tags
    },
    getTagsLoaded (state) {
      return state.tagsLoaded
    },
    getSubTags (state) {
      return state.subTags
    },
    getSubTagsLoaded (state) {
      return state.subTagsLoaded
    }
  },
  mutations: {
    setTags (state, payload) {
      state.tags = payload
      state.tagsLoaded = true
    },
    setSubTags (state, payload) {
      state.subTags = payload
      state.subTagsLoaded = true
    }
  },
  actions: {
    async setTags (state) {
      const url = process.env.VUE_APP_SERVER_URL + '/tags'
      await fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      })
        .then((response) => response.json())
        .then((res) => state.commit('setTags', res.data))
        .catch((e) => {
          console.log(e)
          state.commit('setTags', 'Теги не загружены')
        })
    },
    async setSubTags (state) {
      const url = process.env.VUE_APP_SERVER_URL + '/subtags'
      await fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      })
        .then((response) => response.json())
        .then((res) => state.commit('setSubTags', res.data))
        .catch((e) => {
          console.log(e)
          state.commit('setSubTags', [])
        })
    }
  }
}
