import { getBearer } from '@/helpers/authHelper'

export const masterTendersStatusOneStore = {
  state: {
    masterTendersStatusOne: [],
    masterTendersStatusOneTotal: 0,
    masterTendersStatusOneLoaded: false
  },
  getters: {
    getMasterTendersStatusOne (state) {
      return state.masterTendersStatusOne
    },

    getMasterTendersStatusOneTotal (state) {
      return state.masterTendersStatusOneTotal
    },

    getMasterTendersStatusOneLoaded (state) {
      return state.masterTendersStatusOneLoaded
    }
  },
  mutations: {
    setMasterTendersStatusOne (state, payload) {
      console.log(payload.data)
      state.masterTendersStatusOne = payload.data
      state.masterTendersStatusOneTotal = payload.total
      state.masterTendersStatusOneLoaded = true
    }
  },

  actions: {
    async setMasterTendersStatusOne (state, condition) {
      if (condition) {
        const url =
          process.env.VUE_APP_SERVER_URL + `/requests?page=${condition}`
        await fetch(url, {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: getBearer()
          }
        })
          .then((response) => response.json())
          .then((res) => {
            state.commit('setMasterTendersStatusOne', res.data)
          })
          .catch((e) => {
            console.log(e)
            state.commit('setMasterTendersStatusOne', [])
          })
      } else {
        state.commit('setMasterTendersStatusOne', [])
      }
    }
  }
}
