<template>
  <div class="main__header">
    <div class="main__header__content">
      <!-- <div class="main__header__left__buttons">
        <img
          src="./svg/EnterLogo.svg"
          class="main__header__left__button__logo"
          @click="routeToMainPage"
        />
        <div
          v-if="width > 992"
          @click="showModal"
          class="main__header__left__button__geolocation"
        >
          <img src="./svg/location.svg" />
          <label>{{ geolocation }}</label>
        </div>
      </div> -->
      <div class="main__header__hover__menu" v-if="width < 992">
        <div class="main__header__hover__buttons">
          <img
            src="./svg/EnterLogo.svg"
            class="main__header__left__button__logo"
            @click="routeToMainPage"
          />
          <img @click="showDrawer" src="./svg/menu.svg" />
        </div>
        <a-drawer
          wrapClassName="main__header__drawer__container"
          :closable="false"
          :visible="isOpenDrawer"
          @close="onCloseDrawer"
        >
          <div @click="onCloseDrawer" class="main__header__drawer__close">
            <img src="./svg/close.svg" />
          </div>
          <div
            @click="showModal"
            class="main__header__drawer__geolocation__button"
          >
            <img src="./svg/location.svg" />
            <a-tooltip placement="bottom" :title="geolocation">
              <label>{{ geolocation }}</label>
            </a-tooltip>
          </div>
          <div class="main__header__drawer__buttons">
            <router-link class="main__header__drawer__button" to="/">
              <label>Главная</label>
            </router-link>
            <router-link class="main__header__drawer__button" to="/about">
              <label>О нас</label>
            </router-link>
            <router-link class="main__header__drawer__button" to="/main">
              <label>Тендеры</label>
            </router-link>
            <router-link class="main__header__drawer__button" to="/create_tender">
              <label v-show="!isLogged" class="main__header__drawer__button"
              >Создать тендер</label>
            </router-link>
          </div>

          <div v-show="!isLogged" class="main__header__drawer__buttons__login">
            <router-link
              class="main__header__drawer__buttons__login__button"
              to="/registration_customer"
            >
              <label class="main__header__drawer__button__login">
                Регистрация
              </label>
            </router-link>
            <router-link
              class="main__header__drawer__button__login"
              to="/login"
            >
              <label class="main__header__drawer__button__login">Вход</label>
            </router-link>
          </div>
          <div class="main__header__drawer__create__tender">
            <router-link v-show="isCustomer" to="/create_tender">
              <div>
                <Button :buttonClass="classButton">Создать тендер</Button>
              </div>
            </router-link>
          </div>

          <div
            v-show="isLogged"
            class="main__header__drawer__buttons__login__is__logged"
          >
            <a-popconfirm
              title="Выйти из приложения"
              ok-text="Да"
              cancel-text="Нет"
              @confirm="confirm"
              @cancel="cancel"
            >
              <div v-if="avatar">
                <img
                  class="main__header__personal__avatar"
                  :src="serverPath + avatar"
                />
              </div>
              <div v-if="!avatar">
                <img src="./svg/person.svg" />
              </div>
            </a-popconfirm>

            <label class="main__header__login__button">{{ personName }}</label>
          </div>
        </a-drawer>
      </div>

      <div v-if="width >= 992" class="main__header__big__size">
        <div class="main__header__left__buttons">
          <img
            src="./svg/EnterLogo.svg"
            class="main__header__left__button__logo"
            @click="routeToMainPage"
          />
          <div
            v-if="width > 992"
            @click="showModal"
            class="main__header__left__button__geolocation"
          >
            <img src="./svg/location.svg" />
            <a-tooltip placement="bottom" :title="geolocation" arrow-point-at-center>
              <label>{{ geolocation }}</label>
            </a-tooltip>
          </div>
        </div>

        <div class="main__header__center__buttons__container">
          <router-link to="/">
            <label class="main__header__menu__button">Главная</label>
          </router-link>
          <router-link to="/about">
            <label class="main__header__menu__button">О нас</label>
          </router-link>
          <router-link to="/tenders">
            <label class="main__header__menu__button">Тендеры</label>
          </router-link>
           <router-link to="/create_tender">
          <label v-show="!isLogged" class="main__header__menu__button"
            >Создать тендер</label
          >
          </router-link>
        </div>
        <div v-show="!isLogged" class="main__header__login__buttons">
          <router-link to="/registration_customer">
            <label class="main__header__login__button">Регистрация</label>
          </router-link>
          <router-link to="/login">
            <label class="main__header__login__button">Вход</label>
          </router-link>
        </div>

        <div v-show="isLogged" class="main__header__login__buttons">
          <router-link v-show="isCustomer" to="/create_tender">
            <div class="main__header__button__create__tender">
              <Button :buttonClass="classButton">Создать тендер</Button>
            </div>
          </router-link>
          <a-dropdown>
            <template #overlay>
              <a-menu>
                <a-menu-item key="1">
                  <router-link to="/set_profile">
                    Профиль
                  </router-link>
                </a-menu-item>
                <a-menu-item key="2">
                  <router-link to="/tenders">
                    Tендеры
                  </router-link>
                </a-menu-item>
                <a-menu-item @click="confirm" key="3">
                  Выход
                </a-menu-item>
              </a-menu>
            </template>
            <div
                v-if="avatar"
                class="main__header__personal__avatar__container"
            >
              <img
                  class="main__header__personal__avatar"
                  :src="serverPath + avatar"
              />
              <label class="main__header__login__button">{{
                  personName
                }}</label>
            </div>
            <div
                v-if="!avatar"
                class="main__header__personal__avatar__container"
            >
              <img src="./svg/person.svg" />
              <label class="main__header__login__button">{{
                  personName
                }}</label>
            </div>
          </a-dropdown>
        </div>
      </div>
    </div>
    <a-modal
      :footer="null"
      :visible="isModalVisible"
      @cancel="showModal"
      :closable="false"
      :width="width > 600 ? '80%' : '100%'"
    >
      <GeoModal v-show="isModalVisible" @close="showModal"></GeoModal>
    </a-modal>
  </div>
</template>

<script>
import GeoModal from '@/components/modal/GeoModal.vue'
import Button from '@/components/common/Button.vue'
import throttle from 'lodash/throttle'
import {
  isLogged,
  getPersonName,
  getPersonAvatar,
  isCustomer,
  logout } from '@/helpers/authHelper'

export default {
  props: ['geo-button'],

  name: 'MainHeader',
  components: {
    GeoModal,
    Button
  },

  data () {
    return {
      serverPath: process.env.VUE_APP_SERVER_URL,
      isModalVisible: false,
      isLogged: false,
      isCustomer: false,
      avatar: '',
      personName: '',
      width: window.innerWidth,
      isOpenDrawer: false,
      classButton: 'red-antd-button34'
    }
  },

  computed: {
    // TODO change with personal data
    geolocation () {
      return this.$store.getters.getGeoLocation
    }
  },
  beforeMount () {
    window.addEventListener('resize', this.updateWidth)
    this.isLogged = isLogged()
    this.isCustomer = isCustomer()
    this.avatar = getPersonAvatar()
    this.personName = getPersonName()
    this.$store.dispatch('setGeoLocation', '')
  },
  methods: {
    showDrawer () {
      this.isOpenDrawer = true
    },
    onCloseDrawer () {
      this.isOpenDrawer = false
    },
    updateWidth: throttle(function (e) {
      this.width = window.innerWidth
    }),
    showModal () {
      this.isModalVisible = !this.isModalVisible
    },
    routeToMainPage () {
      this.$router.push('/main')
    },
    confirm (e) {
      this.isLogged = isLogged()
      this.personName = getPersonName()
      this.avatar = getPersonAvatar()
      logout()
    },
    cancel (e) {
      console.log(e)
    }
  }
}
</script>

<style lang="scss">
.main__header {
  background-color: #f6f6f6;
  height: 46px;
  display: flex;
  flex-direction: row;
  padding: 0 15%;
  align-items: center;
  justify-content: space-between;
  box-shadow: 1px 8px 8px 0px rgba(34, 60, 80, 0.2);
  &__hover {
    &__buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  &__content {
    width: 100%;
  }
  &__big__size {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  &__left {
    &__buttons {
      display: flex;
      flex-direction: row;
      flex-direction: row;
      align-items: center;
      width: 100%;
      max-width: 200px;
    }
    &__button {
      &__logo {
        cursor: pointer;
      }
      &__geolocation {
        width: 20%;
        display: flex;
        flex-direction: row;
        white-space: nowrap;
        align-items: center;
        margin-left: 19px;
        cursor: pointer;
        label {
          color: black;
          cursor: pointer;
          margin-left: 10px;
          max-width: 100px;
          min-width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          &:hover{
            color: rgba(0, 0, 0, 0.65);
          }
        }
      }
    }
  }

  &__right__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 5%;
    margin-right: 5%;
    &__container {
      min-width: 300px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  &__drawer {
    &__create__tender {
      margin-bottom: 20px;
    }
    &__container {
    }
    &__close {
      margin-bottom: 50px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    &__geolocation__button {
      display: flex;
      flex-direction: row;
      white-space: nowrap;
      align-items: center;
      cursor: pointer;
      label {
        color: black;
        cursor: pointer;
        margin-left: 10px;
        max-width: 100px;
        min-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        &:hover{
          color: rgba(0, 0, 0, 0.65);
        }
      }
    }
    &__buttons {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      margin-top: 30px;
      margin-bottom: 30px;
      &__login {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        &__is__logged {
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
        }
        &__button {
          color: #b12021;
          margin-bottom: 17px;
          cursor: pointer;
        }
      }
    }
    &__button {
      color: black;
      margin-bottom: 17px;
      cursor: pointer;
      &__login {
        color: #b12021;
        margin-bottom: 17px;
        cursor: pointer;
      }
    }
  }
  &__menu {
    &__buttons {
      display: flex;
      justify-content: space-between;
    }
    &__button {
      color: #000000;
      padding-right: 15px;
      cursor: pointer;
      &:hover{
            color: rgba(0, 0, 0, 0.65);
          }
    }
  }

  &__login__buttons {
    display: flex;
    justify-content: space-between;
  }
  &__login__button {
    margin-left: 15px;
    color: #b12021;
    cursor: pointer;
    display: inline-block;
    width: 110px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  &__personal__avatar {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    cursor: pointer;
    &__container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__button__create__tender {
    margin-right: 5px;
  }
}

.menu-button-drawer {
  color: #000000;
  margin-bottom: 17px;
}

@media (max-width: 1300px) {
  .main__header {
    &__right__buttons {
      padding-left: 0%;
      &__container {
      }
    }
    &__menu__button {
      padding-right: 7px;
    }
    &__login__buttons {
      overflow: hidden;
    }
  }
}

@media (max-width: 992px) {
  .main__header {
    padding: 0 10%;
  }
}

@media (max-width: 600px) {
  .main__header {
    padding: 0 5%;
  }
  .ant-modal {
    width: 100%;
  }
}
</style>
