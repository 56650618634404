import { getBearer } from '@/helpers/authHelper'

export const customerTendersStatusThreeStore = {
  state: {
    customerTendersStatusThree: [],
    customerTendersStatusThreeTotal: 0,
    customerTendersStatusThreeLoaded: false
  },
  getters: {
    getCustomerTendersStatusThree (state) {
      return state.customerTendersStatusThree
    },
    getCustomerTendersStatusThreeTotal (state) {
      return state.customerTendersStatusThreeTotal
    },

    getCustomerTendersStatusThreeLoaded (state) {
      return state.customerTendersStatusThreeLoaded
    }
  },
  mutations: {
    setCustomerTendersStatusThree (state, payload) {
      state.customerTendersStatusThree = payload.data
      state.customerTendersStatusThreeTotal = payload.total
      state.customerTendersStatusThreeLoaded = true
    }
  },
  actions: {
    async setCustomerTendersStatusThree (state, condition) {
      if (condition) {
        const url =
          process.env.VUE_APP_SERVER_URL +
          `/customer-history?page=${condition}`
        await fetch(url, {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: getBearer()
          }
        })
          .then((response) => response.json())
          .then((res) => {
            state.commit('setCustomerTendersStatusThree', res.data)
          })
          .catch((e) => {
            console.log(e)
            state.commit('setCustomerTendersStatusThree', [])
          })
      } else {
        state.commit('setCustomerTendersStatusThree', [])
      }
    }
  }
}
