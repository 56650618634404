import { getBearer } from '@/helpers/authHelper'

export const masterPortfolioStore = {
  state: {
    masterPortfolio: {},
    isMasterPortfolioLoaded: false,
    totalMasterPortfolios: 0
  },
  getters: {
    getMasterPortfolio (state) {
      return state.masterPortfolio
    },
    getMasterPortfolioLoaded (state) {
      return state.isMasterPortfolioLoaded
    },
    getTotalMasterPortfolios (state) {
      return state.totalMasterPortfolios
    }
  },
  mutations: {
    setMasterPortfolio (state, payload) {
      if (payload.data) {
        state.masterPortfolio = payload.data.data
        state.totalMasterPortfolios = payload.data.total
        state.isMasterPortfolioLoaded = true
      } else {
        state.masterPortfolio = []
        state.totalMasterPortfolios = 0
        state.isMasterPortfolioLoaded = true
      }
    }
  },

  actions: {
    async setMasterPortfolio (state, condition) {
      const url =
        process.env.VUE_APP_SERVER_URL + `/portfolios?page=${condition}`
      await fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: getBearer()
        }
      })
        .then((response) => response.json())
        .then((res) => {
          state.commit('setMasterPortfolio', res)
        })
        .catch((e) => {
          console.log(e)
          state.commit('setMasterPortfolio', {})
        })
    }
  }
}
