import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Antd from 'ant-design-vue'
import axios from "axios";


axios.defaults.baseURL = process.env.VUE_APP_SERVER_URL;
axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

Vue.config.productionTip = false

Vue.use(Antd)

Vue.prototype.$http = axios;
// Vue.prototype.$appName = 'Моё приложение';

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
