<template>
  <div class="link__footer">
    <div
      :class="
        isApp
          ? 'link__footer__container__bottom is_app'
          : 'link__footer__container__bottom'
      "
    >
      <span class="link__footer__button">Политика конфиденциальности</span>
<!--      <router-link to="/">-->
        <span class="link__footer__button">Реставрация мебели</span>
<!--      </router-link>-->
      <span class="link__footer__button">© TENDER {{ currentDate }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['isApp'],
  name: 'LinkFooter',
  computed: {
    currentDate() {
      return new Date().getFullYear()
    }
  }
}
</script>
<style lang="scss">
.link__footer {
  width: 100%;
  padding-top: 50px;
  &__container__bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    a {
      display: flex;
    }
  }

  &__button {
    color: #979797;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    margin-right: 20px;
    margin-bottom: 12px;
    &:hover {
      text-decoration: underline;
    }
  }
}

@media (max-width: 992px) {
  .link__footer {
    &__container__bottom {
      &.is_app {
        justify-content: center;
      }
    }
  }
}

@media (max-width: 720px) {
  .link__footer {
    &__container__bottom {
      flex-direction: column;
      &.is_app {
        flex-direction: row;
        text-align: center;
      }
    }
  }
}
</style>
