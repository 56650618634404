<template>
  <div class="error__page">
    <div class="error__page__container">
      <img class="error__page__img" src="@/assets/image/error.svg" alt="" />
      <div class="error__page__titles">
        <div class="error__page__title__main">
          Извините, запрашиваемая страница не найдена
        </div>
        <div class="error__page__title__desc">
          Возможно она была удалена или даже никогда не существовала.Чтобы найти
          нужную информацию, рекомендуем перейти на главную страницу
        </div>
      </div>

      <div class="error__page__button">
        <router-link to="/main">
          <Button buttonClass="white-antd-button-border">На главную</Button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/common/Button.vue'

export default {
  name: 'ErroPage',
  components: {
    Button
  }
}
</script>

<style lang="scss">
.error__page {
  display: flex;
  align-items: center;
  justify-content: center;
  &__container {
    width: 30%;
    margin-top: 178px;
    min-width: 574px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__img {
  }
  &__titles {
    margin: 40px 0px;
    max-width: 457px;
  }
  &__title {
    &__main {
      margin-bottom: 17px;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: center;
    }
    &__desc {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: center;
    }
  }
  &__button {
    width: 180px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: 1200px) {
  .error__page {
    display: flex;
    align-items: center;
    justify-content: center;
    &__container {
      width: 30%;
      margin-top: 150px;
      min-width: 483px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &__img {
    }
    &__titles {
      margin: 40px 0px;
      max-width: 457px;
    }
    &__title {
      &__main {
        margin-bottom: 17px;
      }
      &__desc {
      }
    }
    &__button {
      width: 144px;
      height: 46px;
    }
  }
}
@media (max-width: 992px) {
  .error__page {
    display: flex;
    align-items: center;
    justify-content: center;
    &__container {
      margin-top: 120px;
      max-width: 457px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &__titles {
      margin: 30px 0px 40px;
      max-width: 457px;
    }
    &__title {
      &__main {
        margin-bottom: 17px;
      }
    }
    &__button {
      width: 144px;
      height: 46px;
    }
  }
}
@media (max-width: 600px) {
  .error__page {
    display: flex;
    align-items: center;
    justify-content: center;
    &__container {
      margin-top: 250px;
      max-width: 457px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &__img {
      max-width: 415px;
    }
    &__titles {
      margin: 40px 0px;
      max-width: 457px;
    }
    &__title {
      &__main {
        margin-bottom: 17px;
      }
    }
    &__button {
      width: 144px;
      height: 46px;
    }
  }
}
@media (max-width: 480px) {
  .error__page {
    &__container {
      margin-top: 218px;
      max-width: 448px;
    }
    &__img {
      max-width: 342px;
    }
    &__titles {
      margin: 40px 20px;
      max-width: 100%;
    }
    &__title {
      &__main {
      }
      &__desc {
      }
    }
    &__button {
      width: 214px;
      height: 46px;
    }
  }
}
@media (max-width: 320px) {
  .error__page {
    &__container {
      margin-top: 74px;
      max-width: 288px;
    }
    &__img {
      max-width: 207px;
    }
    &__titles {
      margin: 40px 0px;
      max-width: 288px;
    }
    &__title {
      &__main {
        font-size: 18px;
      }
    }
    &__button {
      width: 135px;
      height: 46px;
    }
  }
}
</style>
