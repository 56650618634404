<template>
  <div class="main-footer">
    <div class="footer-container">
      <div class="footer-container-content">
        <div class="footer-card-number">
          <div class="footer-image">
            <img src="@/assets/image/EnterLogo.svg" />
          </div>

          <div class="footer-card-second-label">
            Remeslohouse - платформа для поиска комерческих тендеров
          </div>
          <div class="footer-line"></div>

          <a href="tel:+78005555555" class="footer-card-main-number-label">8 800 555-55-55</a>

          <div class="footer-card-hide-label">(звонок бесплатный)</div>

          <a href="tel:+78004444444" class="footer-card-second-label">8 800 444-44-44</a>
          <a href="mailto:info@remeslo.ru" class="footer-card-second-label">info@remeslo.ru</a>
        </div>
        <div class="footer-card">
          <p class="footer-card-main-label">Платформа</p>
          <router-link to="/">
            <p class="footer-card-second-label">Главная</p>
          </router-link>
          <router-link to="/about">
            <p class="footer-card-second-label">О нас</p>
          </router-link>
          <router-link to="/create_tender">
            <p class="footer-card-second-label">Создать тендер</p>
          </router-link>
          <router-link to="/tenders">
            <p class="footer-card-second-label">Тендеры</p>
          </router-link>
<!--          <router-link to="/tariff">-->
<!--            <p class="footer-card-second-label">Тарифный план</p>-->
<!--          </router-link>-->
        </div>
<!--        <div class="footer-last-cards">-->
<!--          <div class="footer-card">-->
<!--            <p class="footer-card-main-label">Информация</p>-->
<!--            <p class="footer-card-second-label">Пользовательское соглашение</p>-->
<!--            <p class="footer-card-second-label">Политика конфиденциальности</p>-->
<!--          </div>-->
<!--          <div class="footer-card">-->
<!--            <p class="footer-card-main-label">Приложение</p>-->

<!--            <div class="footer__app__button">-->
<!--              <div class="footer-app-button-content">-->
<!--                <div class="footer-app-button-icon">-->
<!--                  <svg-->
<!--                    width="22"-->
<!--                    height="25"-->
<!--                    viewBox="0 0 22 25"-->
<!--                    fill="none"-->
<!--                    xmlns="http://www.w3.org/2000/svg"-->
<!--                  >-->
<!--                    <path-->
<!--                      d="M21.4777 19.4826C21.0946 20.3561 20.6411 21.16 20.1156 21.8992C19.3994 22.9069 18.8129 23.6044 18.361 23.9917C17.6603 24.6275 16.9097 24.9532 16.1059 24.9716C15.5288 24.9716 14.8329 24.8096 14.0228 24.4809C13.2101 24.1538 12.4632 23.9917 11.7802 23.9917C11.064 23.9917 10.2958 24.1537 9.47418 24.4809C8.65127 24.8096 7.98835 24.9809 7.48149 24.9979C6.71064 25.0303 5.94232 24.6954 5.17543 23.9917C4.68594 23.5704 4.07368 22.8482 3.34019 21.8251C2.55326 20.7326 1.90632 19.4656 1.39941 18.0212C0.85663 16.461 0.584473 14.9503 0.584473 13.4877C0.584473 11.8123 0.951341 10.3673 1.68622 9.15635C2.26378 8.18369 3.03211 7.41646 3.99367 6.85317C4.95522 6.28989 5.99432 6.00288 7.11324 5.98452C7.72549 5.98452 8.52841 6.17139 9.52614 6.53867C10.5211 6.90718 11.1599 7.09409 11.44 7.09409C11.6494 7.09409 12.3591 6.87559 13.5622 6.43994C14.6999 6.03594 15.6601 5.86865 16.4467 5.93457C18.5782 6.10435 20.1796 6.93345 21.2446 8.42725C19.3383 9.56704 18.3953 11.1634 18.4141 13.2114C18.4313 14.8065 19.0177 16.134 20.1703 17.1879C20.6926 17.6771 21.2759 18.0552 21.9249 18.3237C21.7842 18.7264 21.6357 19.1122 21.4777 19.4826ZM16.5891 0.500147C16.5891 1.75044 16.1262 2.91782 15.2035 3.99834C14.09 5.28286 12.7432 6.0251 11.2827 5.90801C11.2632 5.75082 11.2534 5.5926 11.2533 5.43423C11.2533 4.23394 11.7829 2.94941 12.7232 1.89917C13.1927 1.36743 13.7898 0.925244 14.5138 0.57251C15.2364 0.225049 15.9198 0.0328613 16.5625 0C16.5813 0.167139 16.5891 0.334326 16.5891 0.500147Z"-->
<!--                      fill="white"-->
<!--                    />-->
<!--                  </svg>-->
<!--                </div>-->

<!--                <div class="footer-app-button-name">-->
<!--                  <div class="footer-app-button-name-small">Скачать из</div>-->
<!--                  <div class="footer-app-button-name-big">App Store</div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="footer__app__button down">-->
<!--              <div class="footer-app-button-content">-->
<!--                <div class="footer-app-button-icon">-->
<!--                  <svg-->
<!--                    width="20"-->
<!--                    height="24"-->
<!--                    viewBox="0 0 20 24"-->
<!--                    fill="none"-->
<!--                    xmlns="http://www.w3.org/2000/svg"-->
<!--                  >-->
<!--                    <path-->
<!--                      fill-rule="evenodd"-->
<!--                      clip-rule="evenodd"-->
<!--                      d="M18.3458 14.5505L16.1812 15.8594L12.7764 11.7646L15.9612 7.97424L18.3458 9.41619C20.2761 10.5834 20.2761 13.3833 18.3458 14.5505ZM15.0951 7.45052L5.05573 1.37995C4.64771 1.13323 4.21664 0.996811 3.78892 0.955811L12.1267 10.9833L15.0951 7.45052ZM11.473 11.7612L2.59672 1.0861C1.42622 1.46178 0.503418 2.54236 0.503418 3.94711V20.0196C0.503418 21.2804 1.24682 22.2801 2.24548 22.7434L11.473 11.7612ZM3.32066 23.0182C3.89713 23.0552 4.49797 22.924 5.05573 22.5868L15.3158 16.3827L12.1227 12.5426L3.32066 23.0182Z"-->
<!--                      fill="white"-->
<!--                    />-->
<!--                  </svg>-->
<!--                </div>-->

<!--                <div class="footer-app-button-name">-->
<!--                  <div class="footer-app-button-name-small">Скачать из</div>-->
<!--                  <div class="footer-app-button-name-big">Google Play</div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
      <LinkFooter />
    </div>
  </div>
</template>

<script>
import LinkFooter from '@/components/Footers/LinkFooter.vue'

export default {
  name: 'MainFooter',
  components: {
    LinkFooter
  }
}
</script>

<style lang="scss">
.main-footer {
  width: 100%;
  background-color: #e5e5e5;
  padding-top: 50px;
  padding-left: 15%;
  padding-right: 15%;
}

.footer-last-cards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer-container-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer-container-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer-button {
  color: #979797;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 58px;
}
.footer-button-underline {
  color: #979797;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 58px;
  text-decoration: underline;
}

.footer__app__button {
  background-color: #333333;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;

  max-height: 55px;

  height: 100%;
  width: 100%;

  border-radius: 5px;
  &.down {
    margin-top: 17px;
  }
}

.footer-app-button-name {
  display: flex;
  flex-direction: column;
  color: white;
  align-content: center;
  justify-content: center;
  padding: 10px 10px;
}

.footer-app-button-content {
  display: flex;
  flex-direction: row;

  padding: 5px 10px;

  justify-content: center;
}
.footer-app-button-icon {
  display: flex;
  flex-direction: row;

  padding: 10px 10px;
  align-content: center;
  justify-content: center;
}

.footer-card {
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 200px;
}

.footer-card-number {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  width: 100%;
}

.footer-card-main-label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}

.footer-card-second-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: black;

  //&:hover {
  //  color: #696969d9;
  //}
}

.footer-card-hide-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;

  color: #979797;
}

.footer-card-main-number-label {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 22px;
}

.footer-image {
  width: 94px;
  height: 34px;
  margin: 0 0 20px 0;
}

.footer-app-button-name-small {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.footer-app-button-name-big {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.footer-line {
  height: 1px;
  width: 80%;
  background-color: #979797;
  margin: 10px 0 10px 0;
}

@media (max-width: 992px) {
  .main-footer {
    padding-left: 5%;
    padding-right: 5%;
  }
  .footer-last-cards {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media (max-width: 720px) {
  .footer-container-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}
</style>
