import { getBearer } from '@/helpers/authHelper'

export const masterProfileStore = {
  state: {
    masterProfile: {},
    isMasterProfileLoaded: false,
    totalMasterWatchedPortfolios: 0,
    totalMasterWatchedReviews: 0
  },
  getters: {
    getMasterProfile (state) {
      return state.masterProfile
    },
    getMasterProfileLoaded (state) {
      return state.isMasterProfileLoaded
    },
    getTotalMasterWatchedPortfolios (state) {
      return state.totalMasterWatchedPortfolios
    },
    getTotalMasterWatchedReviews (state) {
      return state.totalMasterWatchedReviews
    }
  },
  mutations: {
    setMasterProfile (state, payload) {
      state.masterProfile = payload
      state.totalMasterWatchedPortfolios = payload.portfolios.total + 5
      state.totalMasterWatchedReviews = payload.reviews.total + 5
      state.isMasterProfileLoaded = true
    }
  },

  actions: {
    async setMasterProfile (state, condition) {
      const url =
        process.env.VUE_APP_SERVER_URL +
        `/users/${condition.userUUID}?page=${condition.page}`
      await fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: getBearer()
        }
      })
        .then((response) => response.json())
        .then((res) => {
          state.commit('setMasterProfile', res.data)
        })
        .catch((e) => {
          console.log(e)
          state.commit('setMasterProfile', {})
        })
    }
  }
}

// userUUID: this.userUUID,
// page: this.currentPageActive,
