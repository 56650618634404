import { getBearer } from '@/helpers/authHelper'

export const masterTendersStatusTwoStore = {
  state: {
    masterTendersStatusTwo: [],
    masterTendersStatusTwoTotal: 0,
    masterTendersStatusTwoLoaded: false
  },
  getters: {
    getMasterTendersStatusTwo (state) {
      return state.masterTendersStatusTwo
    },

    getMasterTendersStatusTwoTotal (state) {
      return state.masterTendersStatusTwoTotal
    },

    getMasterTendersStatusTwoLoaded (state) {
      return state.masterTendersStatusTwoLoaded
    }
  },
  mutations: {
    setMasterTendersStatusTwo (state, payload) {
      state.masterTendersStatusTwo = payload.data
      state.masterTendersStatusTwoTotal = payload.total
      state.masterTendersStatusTwoLoaded = true
    }
  },

  actions: {
    async setMasterTendersStatusTwo (state, condition) {
      if (condition) {
        const url =
          process.env.VUE_APP_SERVER_URL +
          `/user-my-tenders?page=${condition}`
        await fetch(url, {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: getBearer()
          }
        })
          .then((response) => response.json())
          .then((res) => {
            state.commit('setMasterTendersStatusTwo', res.data)
          })
          .catch((e) => {
            console.log(e)
            state.commit('setMasterTendersStatusTwo', [])
          })
      } else {
        state.commit('setMasterTendersStatusTwo', [])
      }
    }
  }
}
