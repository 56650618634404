import { getBearer } from '@/helpers/authHelper'

export const masterAlreadyRespondedStore = {
  state: {
    masterAlreadyResponded: true,
    masterAlreadyRespondedLoaded: false
  },
  getters: {
    getMasterAlreadyResponded (state) {
      return state.masterAlreadyResponded
    },
    getMasterAlreadyRespondedLoaded (state) {
      return state.masterAlreadyRespondedLoaded
    }
  },
  mutations: {
    setMasterAlreadyResponded (state, payload) {
      state.masterAlreadyResponded = payload
      state.masterAlreadyRespondedLoaded = true
    }
  },
  actions: {
    async setMasterAlreadyResponded (state, condition) {
      const url =
        process.env.VUE_APP_SERVER_URL + `/already-responded/${condition}`
      await fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: getBearer()
        }
      })
        .then((response) => response.json())
        .then((res) => {
          state.commit('setMasterAlreadyResponded', res.data)
        })
        .catch((e) => {
          console.log(e)
          state.commit('setMasterAlreadyResponded', {})
        })
    }
  }
}
