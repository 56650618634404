
export const cards = [
    {
        id: 1,
        type: 'Реставрация мягкой мебели',
        name: 'https://cdn.pixabay.com/photo/2016/11/23/14/29/living-room-1853203_1280.jpg',
        status: 'Идёт приём заявок'
    },
    {
        id: 2,
        type: 'Реставрация паркета',
        name: 'https://doska-parket.com/images/1c6c813bb9d5494160041c1c4ee2fb70_XL.jpg',
        status: 'Идёт приём заявок'
    },
    {
        id: 3,
        type: 'Золочение поталью',
        name: 'https://blog.hmstudio.com.ua/wp-content/uploads/2017/04/1-1412687567167-e1491914252197.jpg',
        status: 'Идёт приём заявок'
    },
    {
        id: 4,
        type: 'Изготовление лестниц',
        name: 'https://media.istockphoto.com/id/1368159694/de/foto/treppe-zum-dach.jpg?s=1024x1024&w=is&k=20&c=EHGjK4k-XKzTy4eC001URCDe05nD2JYzUhBtM05knLk=',
        status: 'Идёт приём заявок'
    },
    {
        id: 5,
        type: 'Циклевка шкафа',
        name: 'https://cdn.pixabay.com/photo/2016/11/19/13/06/bed-1839184_1280.jpg',
        status: 'Идёт приём заявок'
    },
    {
        id: 7,
        type: 'Покраска',
        name: 'https://st2.depositphotos.com/1154952/6427/i/450/depositphotos_64274409-stock-photo-hand-painting-yellow-wall-by.jpg',
        status: 'Идёт приём заявок'
    },
    {
        id: 8,
        type: 'Реставрация мягкой мебели',
        name: 'https://cdn.pixabay.com/photo/2016/11/18/17/20/living-room-1835923_1280.jpg',
        status: 'Идёт приём заявок'
    },
    {
        id: 10,
        type: 'Покраска',
        name: 'https://cdn.pixabay.com/photo/2017/11/08/09/22/shabby-chic-2929692_1280.jpg',
        status: 'Идёт приём заявок'
    }
]


export const restavration = [
    {
        id: 1,
        type: 'Реставрация мягкой мебели',
        name: 'https://cdn.pixabay.com/photo/2016/11/23/14/29/living-room-1853203_1280.jpg',
        status: 'Идёт приём заявок'
    },
    {
        id: 2,
        type: 'Реставрация мягкой мебели',
        name: 'https://cdn.pixabay.com/photo/2016/11/18/17/20/living-room-1835923_1280.jpg',
        status: 'Идёт приём заявок'
    },
    {
        id: 3,
        type: 'Реставрация мягкой мебели',
        name: 'https://cdn.pixabay.com/photo/2016/11/30/08/48/bedroom-1872196_1280.jpg',
        status: 'Идёт приём заявок'
    },
    {
        id: 4,
        type: 'Реставрация мягкой мебели',
        name: 'https://cdn.pixabay.com/photo/2016/11/18/17/20/living-room-1835923_1280.jpg',
        status: 'Идёт приём заявок'
    },
    {
        id: 5,
        type: 'Реставрация мягкой мебели',
        name: 'https://cdn.pixabay.com/photo/2016/11/18/17/20/living-room-1835923_1280.jpg',
        status: 'Идёт приём заявок'
    }
]


export const painting = [
    {
        id: 6,
        type: 'Покраска',
        name: 'https://st2.depositphotos.com/1154952/7309/i/950/depositphotos_73096523-stock-photo-hand-painting-oil-color-on.jpg',
        status: 'Идёт приём заявок'
    },
    {
        id: 7,
        type: 'Покраска',
        name: 'https://st2.depositphotos.com/1154952/6427/i/450/depositphotos_64274409-stock-photo-hand-painting-yellow-wall-by.jpg',
        status: 'Идёт приём заявок'
    },
    {
        id: 8,
        type: 'Покраска',
        name: 'https://st2.depositphotos.com/1017986/6681/i/950/depositphotos_66814491-stock-photo-male-in-gloves-holding-paintbrush.jpg',
        status: 'Идёт приём заявок'
    },
    {
        id: 9,
        type: 'Покраска',
        name: 'https://st2.depositphotos.com/2631303/6382/i/950/depositphotos_63822237-stock-photo-repairs-in-the-apartment.jpg',
        status: 'Идёт приём заявок'
    },
    {
        id: 10,
        type: 'Покраска',
        name: 'https://cdn.pixabay.com/photo/2018/02/04/09/08/wood-3129350_1280.jpg',
        status: 'Идёт приём заявок'
    }
]

export const creation = [
    {
        id: 6,
        type: 'Изготовление',
        name: 'https://rsmebel.ru/upload/iblock/8c8/dop_uslugi.jpg',
        status: 'Идёт приём заявок'
    },
    {
        id: 7,
        type: 'Изготовление',
        name: 'https://cs11.pikabu.ru/post_img/big/2020/01/11/2/157870180815788409.jpg',
        status: 'Идёт приём заявок'
    },
    {
        id: 8,
        type: 'Изготовление',
        name: 'https://cdn.pixabay.com/photo/2016/11/30/08/48/bedroom-1872196_1280.jpg',
        status: 'Идёт приём заявок'
    },
    {
        id: 9,
        type: 'Изготовление',
        name: 'https://cs9.pikabu.ru/post_img/big/2020/01/11/2/1578702008170187491.jpg',
        status: 'Идёт приём заявок'
    },
    {
        id: 10,
        type: 'Изготовление',
        name: 'https://cdn.pixabay.com/photo/2016/11/18/17/20/living-room-1835923_1280.jpg',
        status: 'Идёт приём заявок'
    }
]



export const other = [
    {
        id: 3,
        type: 'Золочение поталью',
        name: 'https://blog.hmstudio.com.ua/wp-content/uploads/2017/04/1-1412687567167-e1491914252197.jpg',
        status: 'Идёт приём заявок'
    },
    {
        id: 4,
        type: 'Изготовление лестниц',
        name: 'https://media.istockphoto.com/id/1368159694/de/foto/treppe-zum-dach.jpg?s=1024x1024&w=is&k=20&c=EHGjK4k-XKzTy4eC001URCDe05nD2JYzUhBtM05knLk=',
        status: 'Идёт приём заявок'
    },
    {
        id: 5,
        type: 'Циклевка шкафа',
        name: 'https://cdn.pixabay.com/photo/2016/11/19/13/06/bed-1839184_1280.jpg',
        status: 'Идёт приём заявок'
    },
    {
        id: 6,
        type: 'переклейка стула',
        name: 'https://cs13.pikabu.ru/post_img/big/2020/01/15/6/1579075675160628790.jpg',
        status: 'Идёт приём заявок'
    },
    {
        id: 7,
        type: 'переклейка стула',
        name: 'https://cs8.pikabu.ru/post_img/big/2016/09/15/12/147396978514440432.jpg',
        status: 'Идёт приём заявок'
    },
]
