import { getBearer } from '@/helpers/authHelper'

export const tendersStore = {
  state: {
    allTenders: [],
    allTotalTenders: 0,
    isAllLoaded: false,

    tender: {},
    isTenderLoaded: false
  },
  getters: {
    getAllTenders (state) {
      return state.allTenders
    },
    getAllLoaded (state) {
      return state.isAllLoaded
    },

    getAllTotalTenders (state) {
      return state.allTotalTenders
    },

    getTender (state) {
      return state.tender
    },

    getTenderLoaded (state) {
      return state.isTenderLoaded
    }
  },
  mutations: {
    setAllTenders (state, payload) {
      console.log(payload)
      state.allTenders = payload.data
      state.allTotalTenders = payload.total
      state.isAllLoaded = true
    },

    setIsAllLoaded (state, payload) {
      state.isAllLoaded = payload
    },

    setTender (state, payload) {
      state.tender = payload
      state.isTenderLoaded = true
    },

    setIsTenderLoaded (state, payload) {
      state.isTenderLoaded = payload
    },
  },
  actions: {
    async setAllTenders (state, condition) {
      if (condition.page) {
        state.commit('setIsAllLoaded', false)
        // if (condition.tags && condition.tags.length !== 0 || condition.city) {
        if (condition.tags && condition.tags.length !== 0) {
          console.log('setAllTenders')
          const url = process.env.VUE_APP_SERVER_URL + `/tenders-by-subtags?page=${condition.page}`
          let subtagsId = {}

          if (condition.address) {
            subtagsId.address = condition.address
          }
          if (condition.tags) {
            subtagsId.subtagsId = condition.tags
          }
          await fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: getBearer()
            },
            body: JSON.stringify(subtagsId)
          })
            .then((response) => response.json())
            .then((res) => {
              state.commit('setAllTenders', res.data)
            })
            .catch((e) => {
              console.log(e)
              state.commit('setAllTenders', [])
            })
        } else {
          const url = process.env.VUE_APP_SERVER_URL + `/tenders?page=${condition.page}${condition.address ? '&address=' + condition.address : ''}`
          await fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: getBearer()
            }
          })
            .then((response) => response.json())
            .then((res) => {
              state.commit('setAllTenders', res.data)
            })
            .catch((e) => {
              console.log(e)
              state.commit('setAllTenders', [])
            })
        }
      } else {
        state.commit('setAllTenders', [])
      }
    },

    async setTender (state, condition) {
      state.commit('setIsTenderLoaded', false)
      const url = process.env.VUE_APP_SERVER_URL + `/tenders/${condition}`
      await fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: getBearer()
        }
      })
        .then((response) => response.json())
        .then((res) => {
          state.commit('setTender', res.data)
        })
        .catch((e) => {
          console.log(e)
          state.commit('setTender', 0)
        })
    }
  }
}
